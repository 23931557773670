<script setup lang="ts">
import refs from "@/composables/common/search_forms/form_select_pay_type/refs";
import cmpGroupedPayTypes from "@/composables/common/search_forms/form_select_pay_type/computed/cmpGroupedPayTypes";
import watchPayTypeGroupId from "@/composables/common/search_forms/form_select_pay_type/watch/watchPayTypeGroupId";

import { useStore, Store } from 'vuex';
import { key } from '@/vuex';
import type { State } from '@/vuex';
const store:Store<State> = useStore(key);

interface Props {
  payTypeGroupLabel: string; // pay_type_groupのラベルテキスト
  payTypeLabel: string; // pay_typeのラベルテキスト
  payTypeGroupList: Array<any>; // pay_type_groupの内訳（[名称, id]の配列）
  payTypeList: Array<any>; // pay_typeの内訳（[名称, id]の配列）
  payTypeGroupIdValue: number|null; // 現在のpay_type_group_idの値
  payTypeIdValue: number|null; // 現在のpay_type_idの値
}
const props = defineProps<Props>();

// == ref ==
const { payTypeGroupId, payTypeId } = refs(props.payTypeGroupIdValue, props.payTypeIdValue);
// == computed ==
const { groupedPayTypes } = cmpGroupedPayTypes(props.payTypeList, payTypeGroupId);
// == watch ==
watchPayTypeGroupId(payTypeGroupId, payTypeId);
</script>

<template lang="pug">
div
  .form-group.uk-flex.uk-margin-small-bottom
    .uk-form-horizontal.uk-margin-small-right
      label.string.uk-form-label(for="q_pay_type_pay_type_group_id_eq")
        |  {{ payTypeGroupLabel }}
      .uk-form-controls
        select.select.uk-select.juno-form-width-ml.juno-search-form.juno-search-form-clear(
          v-model="payTypeGroupId"
          :id="`q_pay_type_pay_type_group_id_eq`"
          :name="`q[pay_type_pay_type_group_id_eq]`"
        )
          option(value="") {{`${payTypeGroupLabel}を選択`}}
          option(v-for="payTypeGroup in payTypeGroupList" :value="payTypeGroup[1]") {{payTypeGroup[0]}}
  .form-group.uk-flex
    .uk-form-horizontal.uk-margin-small-right
      label.string.uk-form-label(for="q_pay_type_id_eq")
        |  {{ payTypeLabel }}
      .uk-form-controls
        select.select.uk-select.juno-form-width-ml.juno-search-form.juno-search-form-clear(
          v-model="payTypeId"
          :id="`q_pay_type_id_eq`"
          :name="`q[pay_type_id_eq]`"
        )
          option(value="") {{`${payTypeLabel}を選択`}}
          option(v-for="payType in groupedPayTypes" :value="payType[1]") {{payType[0]}}
</template>
