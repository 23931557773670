import BinderBase from '@/binders/binder_base';
import ApprovalStatus from '@/components/common/panels/items/index/approval_status';

class ApprovalStatusBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '.approval-status',
        app: ApprovalStatus
      }
    ];
  }
}

export default ApprovalStatusBinder;
