<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  value?:any;
  baseUrl?:string;
  cellClass?: string;
  ellipsis?: boolean;
  tooltip?: string;
}
const props = withDefaults(defineProps<Props>(), {
  value: '',
  baseUrl: 'javascript:void(0)',
  cellClass: '',
  ellipsis: false,
  tooltip: ''
});

const isLink = computed(() => props.baseUrl !== 'javascript:void(0)');
const cellAllClass = computed(() => props.ellipsis ? `${props.cellClass} uk-text-truncate` : props.cellClass);
</script>

<template lang="pug">
td
  a.uk-link-reset(v-if="isLink" :href="baseUrl" :class="cellAllClass")
    span(:uk-tooltip="tooltip") {{value}}
  .juno-search-result-table-cell(v-else :class="cellAllClass")
    span(v-if="value" :uk-tooltip="tooltip") {{value}}
    slot(v-else)
</template>
