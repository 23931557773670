import BinderBase from '@/binders/binder_base';
import FormSelectCustomTemplate from '@/components/common/other_forms/form_select_custom_template';

class FormSelectCustomTemplateBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#form_select_custom_template',
        app: FormSelectCustomTemplate
      }
    ];
  }
}

export default FormSelectCustomTemplateBinder;
