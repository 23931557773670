import BinderBase from '@/binders/binder_base';
import PsdButtonClearSearch from '@/components/payment_statements/donations/psd_button_clear_search';

class PsdButtonClearSearchBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#psd_button_clear_search',
        app: PsdButtonClearSearch
      }
    ];
  }
}

export default PsdButtonClearSearchBinder;
