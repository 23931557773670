import MailCustomTemplate from "@/models/MailCustomTemplate";
import { ukConfirm } from "@/composables/common/common/methods/mtdUIKit";

export default () => {
  const copyTemplate = (mailCustomTemplate: MailCustomTemplate) => {
    const elReplyTo = document.getElementById("mail_schedule_reply_to") as HTMLInputElement;
    const elSubject = document.getElementById("mail_schedule_subject") as HTMLInputElement;
    const elBody = document.getElementById("mail_schedule_body") as HTMLInputElement;

    if (elReplyTo.value || elSubject.value || elBody.value) {
      ukConfirm('入力中の内容があります。上書きしますか？', '上書きする', 'キャンセル')
        .then(() => copyToForms(mailCustomTemplate, elReplyTo, elSubject, elBody))
    } else {
      copyToForms(mailCustomTemplate, elReplyTo, elSubject, elBody)
    }
  }

  return { copyTemplate }
}

function copyToForms(
  mailCustomTemplate: MailCustomTemplate,
  elReplyTo: HTMLInputElement,
  elSubject: HTMLInputElement,
  elBody: HTMLInputElement) {

  // 返信先アドレス
  elReplyTo.value = !mailCustomTemplate.replyTo ? '' : mailCustomTemplate.replyTo;

  // タイトル
  elSubject.value = !mailCustomTemplate.subject ? '' : mailCustomTemplate.subject;

  // 本文
  elBody.value = !mailCustomTemplate.body ? '' : mailCustomTemplate.body;
}
