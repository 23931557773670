import { ref } from "vue";

export default (month:number|null, date:number|null) => {
  const selectedMonth = ref(month);
  const selectedDate = ref(date);

  return {
    selectedMonth,
    selectedDate
  };
};
