<script setup lang="ts">
import refs from "@/composables/common/panels/donations/edit/panel_inquiries_table/refs";
import cmpInquiries from "@/composables/common/panels/donations/edit/panel_inquiries_table/computed/cmpInquiries";
import mtdGetInquiries from "@/composables/common/panels/donations/edit/panel_inquiries_table/methods/mtdGetInquiries";
import mtdOpenModal from "@/composables/common/panels/donations/edit/panel_inquiries_table/methods/mtdOpenModal";
import TableData from "@/components/common/panels/table_data.vue";
import ModalInquiry from "@/components/common/panels/donations/edit/modal_inquiry.vue";

import { useStore, Store } from 'vuex';
import { key } from '@/vuex';
import type { State } from '@/vuex';
const store:Store<State> = useStore(key);

interface Props {
  idKey:string,
  donationId:number,
  headers:Array<string>,
  baseUrl:string
}
const props = defineProps<Props>();

// 問い合わせ履歴一覧のtable要素のid
const modalIdKey = 'update_inquiry_modal';

// == ref ==
const { selectedInquiry } = refs();
// == computed ==
const { inquiries } = cmpInquiries(store);
// == methods ==
const { getInquiries } = mtdGetInquiries(store);
const { openModal } = mtdOpenModal(modalIdKey, selectedInquiry);

// 問い合わせデータ取得
getInquiries(props.baseUrl, props.donationId);
</script>

<template lang="pug">
.uk-overflow-auto.juno-inquiries-table.uk-background-default
  table.uk-table.uk-table-small.uk-table-hover.uk-table-divider.uk-margin-remove-bottom(:id="idKey")
    thead
      tr.juno-inquiries-table-header
        th(class="uk-width-medium@m")
          | 問い合わせ日時
        th(class="uk-width-small@m")
          | 記入者
        th(class="uk-width-large@s")
          | 問い合わせ内容

    tbody
      tr(v-for="inquiry in inquiries" :key="inquiry.id" @click="openModal(inquiry)")
        TableData(:value="inquiry.receivedAtSqlFormat")
        TableData(:value="inquiry.userName")
        TableData(:value="inquiry.body")

  ModalInquiry(
    :id-key="modalIdKey"
    :request-path="baseUrl"
    :donation-id="donationId"
    :inquiry-id="selectedInquiry?.id"
    :received-at-value="selectedInquiry?.receivedAtSqlFormat"
    :body-value="selectedInquiry?.body"
  )
</template>
