import BinderBase from '@/binders/binder_base';
import FormRedhorseIntegrationSetting from '@/components/common/panels/cities/form_redhorse_integration_setting';

class FormRedhorseIntegrationSettingBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#form_redhorse_integration_setting',
        app: FormRedhorseIntegrationSetting
      }
    ];
  }
}

export default FormRedhorseIntegrationSettingBinder;
