<script setup lang="ts">
import cmpRequiredClass from "@/composables/common/input_forms/common/cmpRequiredClass";

interface Props {
  labelText: string; // 入力フォームのラベルテキスト
  required: boolean; // 必須かどうか
  modelName: string; // 自model名
  columnName: string; // カラム名
  modelValue?: string; // 入力文字列
  rows: number; // 行数
  cols: number; // 1行の文字数
}
const props = withDefaults(defineProps<Props>(), {
  required: false,
  modelValue: ''
});
const emit = defineEmits(['update:modelValue']);
const onInput = () => emit('update:modelValue', props.modelValue);

// 必須用class名（for style）
const requiredClass = cmpRequiredClass(props.required);
</script>

<template lang="pug">
.form-group.uk-flex
  .uk-form-horizontal.uk-margin-small-right
    label.uk-form-label(:class='requiredClass')
      abbr(v-if="required" title='必須') *
      |  {{ labelText }}
    .uk-form-controls
      div(:class="`${requiredClass}`")
        textarea.uk-textarea(
          v-model="modelValue"
          :id="`${modelName}_${columnName}`"
          :name="`${modelName}[${columnName}]`"
          :rows="rows"
          :cols="cols"
          @input="onInput"
        )
</template>
