<script setup lang="ts">
import refs from "@/composables/common/panels/shared_files/form_thumbnail/refs";

import { useStore, Store } from 'vuex';
import { key } from '@/vuex';
import type { State } from '@/vuex';
const store:Store<State> = useStore(key);

interface Props {
  railsDirectUploadsUrl:string;
	thumbnailBlobId:string;
	thumbnailName:string;
}
const props = defineProps<Props>();

// == ref ==
const { thumbnailBlobId, thumbnailName } = refs(props);
</script>

<template lang="pug">
.uk-grid(class="uk-child-width-1-1@m uk-child-width-1-2@l")
	.uk-margin-small-bottom
		.form-group.uk-flex
			.uk-form-horizontal
				.uk-form-label.uk-flex
					label.uk-flex サムネイル
				.uk-form-controls
					div(uk-form-custom="target: true")
						input(type="file" name="shared_file[thumbnail]" id="shared_file_thumbnail" :data-direct-upload-url="railsDirectUploadsUrl")
						input.uk-input.uk-form-width-medium(type="text", :placeholder="thumbnailName || 'ファイルを選択'")
						input(v-if="thumbnailBlobId" name="shared_file[thumbnail_blob_id]" id="shared_file_thumbnail_blob_id" :value="thumbnailBlobId" class='uk-hidden')
</template>
