import { Ref } from "vue";

export default (id: Ref<string|null>, storeIds: Ref<string[]>, recordedDate: Ref<Date|null>, name: Ref<string>, amount: Ref<string>, taxRate: Ref<string>) => {
  const onClear = () => {
    id.value = '';
    storeIds.value = [];
    recordedDate.value = null;
    name.value = '';
    amount.value = '';
    taxRate.value = '';
  }

  return { onClear }
}
