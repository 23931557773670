import BinderBase from '@/binders/binder_base';
import DisplayDownloadFiles from '@/components/common/buttons/display_download_files';

class DisplayDownloadFilesBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#display_download_files',
        app: DisplayDownloadFiles
      }
    ];
  }
}

export default DisplayDownloadFilesBinder;
