<script setup lang="ts">
import mtdFormatDatetime from "@/composables/common/common/methods/mtdFormatDatetime";
import Datepicker from '@vuepic/vue-datepicker';
import ClockIcon from '@vuepic/vue-datepicker/src/VueDatePicker/components/Icons/ClockIcon.vue';
import CalendarIcon from '@vuepic/vue-datepicker/src/VueDatePicker/components/Icons/CalendarIcon.vue';

interface Props {
  labelText: string; // 入力フォームのラベルテキスト
  modelName: string; // 自model名
  columnName: string; // カラム名
  modelValue?: Date|null; // 日時(Date型）
  modalForm?: boolean; // モーダルのフォームかどうか
  disabled?: boolean; // disabledかどうか
  requiredLabel?: boolean; // 必須ラベルの表示
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  modalForm: false,
  disabled: false,
  requiredLabel: false
});
const emit = defineEmits(['update:modelValue']);
const onUpdate = () => emit('update:modelValue', props.modelValue);

// == methods ==
const { formatDatetime } = mtdFormatDatetime();
</script>

<template lang="pug">
.form-group.uk-flex
  .uk-form-horizontal.uk-margin-small-right
    label.uk-form-label.uk-flex
      |  {{ labelText }}
      span.uk-label-danger.juno-required-label(v-if="requiredLabel") 必須
    .uk-form-controls
      div
        Datepicker.datepicker.string.optional.juno-form-datetime(
          v-model="modelValue"
          :id="`${modelName}_${columnName}`"
          :name="`${modelName}[${columnName}]`"
          :format="formatDatetime"
          locale="ja"
          weekStart="0"
          :previewFormat="formatDatetime"
          :disabled="disabled"
          selectText="決定"
          cancelText="キャンセル"
          @update:modelValue="onUpdate"
          enableSeconds
          inputClassName="uk-text-muted"
        )
          template(#clock-icon)
            ClockIcon.uk-margin-small-right
            span.uk-text-bold.uk-text-muted 時刻を選択
          template(#calendar-icon)
            CalendarIcon.uk-margin-small-right
            span.uk-text-bold.uk-text-muted 日付を選択
</template>
