import { InjectionKey } from 'vue';
import { createStore, Store } from 'vuex';
import { downloadFiles, downloadFilesState } from '@/vuex/store/downloadFiles';
import { inquiries, inquiriesState } from '@/vuex/store/inquiries';
import { items, itemsState } from '@/vuex/store/items';
import { optionalStatements, optionalStatementsState } from '@/vuex/store/optionalStatements';
import { paymentStatementSetting, paymentStatementSettingState } from '@/vuex/store/paymentStatementSetting';
import { tempPaymentSummaries, tempPaymentSummariesState } from '@/vuex/store/tempPaymentSummaries';

export interface State {
  downloadFiles: downloadFilesState;
  inquiries: inquiriesState;
  items: itemsState;
  optionalStatements: optionalStatementsState;
  paymentStatementSetting: paymentStatementSettingState;
  tempPaymentSummaries: tempPaymentSummariesState;
}

export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  modules: {
    downloadFiles,
    inquiries,
    items,
    optionalStatements,
    paymentStatementSetting,
    tempPaymentSummaries
  }
});
