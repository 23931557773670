// 引数の文字列をキャメルケースに変換
import UIkit from "uikit";

const ukNotify = (message:string) => {
  return UIkit.notification(message, notifyOptions );
}

const notifyOptions = {
  clsContainer: 'juno-notify-container uk-notification',
  clsMsg: 'uk-text-small uk-notification-message'
}

const ukConfirm = (message:string, ok:string = 'OK', cancel:string = 'キャンセル') => {
  return UIkit.modal.confirm(message, { labels: { ok, cancel } });
}

export {
  ukNotify,
  ukConfirm
}
