export default () => {
  const formatDatetime = (datetime:Date|null) => {
    if (!datetime) return '';

    const year = datetime.getFullYear();
    const month = `0${datetime.getMonth() + 1}`.slice(-2);
    const day = `0${datetime.getDate()}`.slice(-2);
    const hour = `0${datetime.getHours()}`.slice(-2);
    const min = `0${datetime.getMinutes()}`.slice(-2);
    const sec = `0${datetime.getSeconds()}`.slice(-2);

    return `${year}-${month}-${day} ${hour}:${min}:${sec}`;
  }

  return { formatDatetime }
}
