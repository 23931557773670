<script setup lang="ts">
import mtdRandomPassword from "@/composables/common/buttons/button_random_password/methods/mtdRandomPassword";
import mtdSetPassword from "@/composables/common/buttons/button_random_password/methods/mtdSetPassword"

interface Props {
  buttonText: string; // 入力フォームのラベルテキスト
  modelName: string; // 自model名
}
const props = defineProps<Props>();

// == methods ==
const { randomPassword } = mtdRandomPassword();
const { setPassword } = mtdSetPassword(props.modelName, randomPassword);
</script>

<template lang="pug">
button.uk-button.uk-button-default(@click.prevent="setPassword") {{ buttonText }}
</template>
