import { Ref } from 'vue';

export default (formDatas:Array<any>, customerSurveyChoices:Array<any>, countClick:Ref<number>, formDataOrders:Array<number>, existDataLastOrder:Ref<number>) => {
  const clickAdd = () =>{
    const addForm = {"id": null, "customer_survey_id": null, "display_text": "", "select_value": null, "order": null, "input_required": null, "discarded_at": null };
    formDatas.push(addForm);
    // Mount時の不変の配列最後の数値にクリック回数を足した数値を、不変の配列に追加
      countClick.value++
      formDataOrders.push(existDataLastOrder.value + countClick.value);
  };
  
  return { clickAdd }
}