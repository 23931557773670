<script setup lang="ts">
import refs from "@/composables/common/input_forms/form_select_store/refs";
import cmpRequiredClass from "@/composables/common/input_forms/common/cmpRequiredClass";
import cmpUrl from "@/composables/common/input_forms/form_select_store/computed/cmpUrl";
import ButtonLink from "@/components/common/buttons/button_link.vue";

interface Props {
  labelText: string; // 入力フォームのラベルテキスト
  required: boolean; // 必須かどうか
  modelName: string; // 自model名
  storeList: Array<any>; // storeの内訳（[名称, id]の配列）
  storeIdValue: number|null; // 現在のstore_idの値
  storesUrl: string; // 店舗情報のbaseパス
  disabled: boolean; // disabledかどうか
}
const props = withDefaults(defineProps<Props>(), {
  required: false,
  storeIdValue: null,
  disabled: false
});

// == ref ==
const { selectedId } = refs(props.storeIdValue);
// == computed ==
// 必須用class名（for style）
const requiredClass = cmpRequiredClass(props.required);
// 店舗情報リンク先URL
const { url } = cmpUrl(props.storesUrl, selectedId);
</script>

<template lang="pug">
.form-group.uk-flex
  .uk-form-horizontal.uk-margin-small-right
    label.string.uk-form-label(:class='requiredClass')
      abbr(v-if="required" title='必須') *
      |  {{ labelText }}
    .uk-form-controls
      .input.select.field_without_errors(:class="`${requiredClass} store_id`")
        select.is-valid.select.uk-select.uk-form-width-medium(
          v-model="selectedId"
          :id="`${modelName}_store_id`"
          :class="requiredClass"
          :name="`${modelName}[store_id]`"
          :disabled="disabled"
        )
          option(v-for="store in storeList" :value="store[1]") {{store[0]}}
  ButtonLink.juno-form-select-store-button.uk-background-default(buttonText="店舗情報" :url="url")
</template>
