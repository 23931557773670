<script setup lang="ts">
import refs from "@/composables/common/panels/store_settings/edit/form_invoice_info/refs";

import { useStore, Store } from 'vuex';
import { key } from '@/vuex';
import type { State } from '@/vuex';
const store:Store<State> = useStore(key);

interface Props {
	invoiceFlag:boolean;
	eligibleBusinessNumberValue:string;
	roundingTaxOptions:Array<any>; // rounding_taxの選択肢（[名称, key(string)]の配列）
	roundingTaxIdValue:string;
}
const props = defineProps<Props>();

const emit = defineEmits(['update:invoiceFlag']);
const onChange = () => {
	emit('update:invoiceFlag', selectedInvoiceFlag);
}
// == ref ==
const { selectedRoundingTax, selectedInvoiceFlag, eligibleBusinessNumber } = refs(props);
</script>

<template lang="pug">
.uk-width-1-1.uk-margin-small-bottom
	.form-group.uk-flex
		.uk-form-horizontal.juno-form-height
			.uk-form-label.uk-flex
				label.uk-flex インボイス制度対応
			.uk-form-controls.juno-radio-buttons.uk-flex
				label.juno-radio-button
					input(type="radio" :value="true" key="invoiceFlag" v-model="selectedInvoiceFlag" name="store[store_setting_attributes][invoice_flag]" id="store_setting_attributes_invoice_flag_true")
					| 有効
				label.juno-radio-button
					input(type="radio" :value="false" key="invoiceFlag" v-model="selectedInvoiceFlag" name="store[store_setting_attributes][invoice_flag]" id="store_setting_attributes_invoice_flag_false")
					| 無効
.uk-grid(class="uk-child-width-1-1@m uk-child-width-1-2@l")
	.uk-margin-small-bottom
		.form-group.uk-flex(v-if="selectedInvoiceFlag")
			.uk-form-horizontal
				.uk-form-label.uk-flex
					label.uk-flex 適格事業者番号
					span.uk-label-danger.juno-required-label 必須
				.uk-form-controls
					input.uk-input.juno-form-width-ml(v-model="eligibleBusinessNumber" name="store[store_setting_attributes][eligible_business_number]" id="store_setting_attributes_eligible_business_number")
	.uk-margin-small-bottom
		.form-group.uk-flex(v-if="selectedInvoiceFlag")
			.uk-form-horizontal.uk-margin-small-right
				.uk-form-label.uk-flex
					label.uk-flex 消費税の丸め方法
					span.uk-label-danger.juno-required-label 必須
				.uk-form-controls
					select.uk-select.uk-form-width-medium(v-model="selectedRoundingTax" name="store[store_setting_attributes][rounding_tax]" id="store_setting_attributes_rounding_tax")
						option(:value="null") 選択してください
						option(v-for="target in roundingTaxOptions" :value="target[1]") {{ target[0] }}
</template>
