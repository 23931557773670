import axios from "@/libs/Axios";
import { AxiosResponse } from 'axios';
import UIkit from 'uikit';
import { ukNotify } from "@/composables/common/common/methods/mtdUIKit";
import { Store } from 'vuex';
import { State } from '@/vuex';
import { Inquiry } from "@/models";
import mtdFormatDatetime from "@/composables/common/common/methods/mtdFormatDatetime";

interface Emits {
  (e: 'update:receivedAt', receivedAt:Date|null): void
  (e: 'update:body', body:string): void
}
export default (store:Store<State>) => {
  const onClick = async (requestPath:string, donationId:number, receivedAt:Date|null, body:string, emit:Emits) => {
    // 問い合わせデータをcreate
    axios
      .post(requestPath, createParams(donationId, receivedAt, body))
      .then((response:AxiosResponse) => {
        const resData = response && response.data ? response.data : {};
        const inquiry = new Inquiry(resData);
        // storeに追加してヘッダのアイコン表示を変える
        store.commit('inquiries/push', inquiry)

        if (inquiry?.id) {
          clearForms(emit);
          closeModal();
        } else {
          notifyError();
        }
        return true;
      })
      .catch(() => {
        notifyError();
        return null;
      });
  }

  function createParams (donationId:number, receivedAt:Date|null, body:string) {
    const { formatDatetime } = mtdFormatDatetime();
    return {
      'inquiry': {
        'donation_id': donationId,
        'received_at': formatDatetime(receivedAt),
        'body': body
      }
    };
  }

  function notifyError() {
    ukNotify('問い合わせを登録できませんでした。');
  }

  function closeModal() {
    const element = document.getElementById('add_inquiry_modal');
    UIkit.modal(element)?.hide();
    ukNotify('問い合わせを登録しました。');
  }

  function clearForms(emit:Emits) {
    emit('update:receivedAt', null);
    emit('update:body', '');
  }

  return { onClick }
}
