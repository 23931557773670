export default () => {
  const clearSearch = () => {
    // 検索条件入力フォーム要素取得
    const search_fields =
      document.getElementsByClassName('juno-search-form-clear') as HTMLCollectionOf<HTMLInputElement>;

    // チェックを外す or 空文字設定
    Array.from(search_fields).forEach(el => {
      // disabledの場合はクリアしない
      if (el.disabled) return;

      if (['checkbox', 'radio'].includes(el.type)) {
        el.checked = false;
      } else {
        el.value = "";
      }
    });
  }

  return { clearSearch }
}
