import BinderBase from '@/binders/binder_base';
import PanelDownloadButtons from '@/components/common/panels/donations/index/panel_download_buttons';

class PanelDownloadButtonsBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#panel_download_buttons',
        app: PanelDownloadButtons
      }
    ];
  }
}

export default PanelDownloadButtonsBinder;
