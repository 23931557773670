export class BaseModel {
  protected _data: any = {};
  protected _defaultValues: any = {};

  constructor (data = {}) {
    this.defineDefaultValues();
    this.assignDefaultAttributes(data);
  }

  defineDefaultValues () {
    this._defaultValues = {};
  }

  assignDefaultAttributes (data: any = {}) {
    this.assignAttributes(Object.assign({}, this._defaultValues, data));
  }

  assignAttributes (data: any) {
    this._data = Object.assign({}, this._data, data);
  }

  assignAttribute (key: string, value: any) {
    this._data[key] = value;
  }

  toParams () {
    return this.attributes;
  }

  params () {
    return this.toParams();
  }

  get attributes () {
    return this._data;
  }
}
