import BinderBase from '@/binders/binder_base';
import ButtonGroup from '@/components/common/panels/items/common/button_group';

class ButtonGroupBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#button_group',
        app: ButtonGroup
      }
    ];
  }
}

export default ButtonGroupBinder;
