import { createApp } from 'vue';
import { store, key } from '@/vuex';

class BinderBase {
  constructor () {
    // targets は、要素にel, appをもつオブジェクトの配列
    // サブクラスで上書きすること
    this.targets = [
      {
        el: '',
        app: null
      }
    ];
  }

  start () {
    document.addEventListener("turbolinks:load", () => {
      this.targets.forEach(target => {
        const elements = this._targetElements(target.el);
        elements.forEach(element => {
          const params = this._parseDataset(element.dataset);
          const app = createApp(target.app, params);
          app.use(store, key).mount(`#${element.id}`);
        });
      });
    });
  }

  _targetElements (key) {
    const prefix = key.substring(0, 1);
    const keyText = key.substring(1);
    let elements;

    switch(prefix) {
      case '.': // クラス
        elements = Array.from(document.getElementsByClassName(keyText));
        break;
      case '#': // id
        const element = document.getElementById(keyText);
        elements = element ? [element] : [];
        break;
      default: // 指定方法が不正
        elements = [];
    }
    return elements;
  }

  // datasetは値を文字列でしか受け取れないため
  // vue同様、v-bindで式として評価する処理に加え
  // jsonをparseする
  _parseDataset (dataset) {
    return Object.keys(dataset).reduce((result, key) => {
      const usedPrefix = [':', 'vBind:', 'json:', 'date:', 'boolean:'].find(prefix => key.startsWith(prefix));
      const newKey = usedPrefix ? key.slice(usedPrefix.length) : key;

      switch (usedPrefix) {
        case ':':
        case 'vBind:':
          // Vueのv-bind:に相当
          const value = eval(dataset[key]);
          result[newKey] = typeof value === 'undefined' ? null : value; // undefinedはnullに置き換える
          break;
        case 'json:':
          // jsonをパース（vueにはないオリジナルの処理）
          result[newKey] = JSON.parse(dataset[key]);
          break;
        case 'date:':
          // Date型に変換（vueにはないオリジナルの処理）
          result[newKey] = new Date(Date.parse(dataset[key]));
          break;
        case 'boolean:':
          // boolean型に変換（vueにはないオリジナルの処理）
          result[newKey] = dataset[key] === 'true';
          break;
        default:
          result[newKey] = dataset[key];
      }

      return result;
    }, {})
  }
}

export default BinderBase;
