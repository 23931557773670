import axios from "@/libs/Axios";
import { AxiosResponse } from 'axios';
import { ukNotify } from "@/composables/common/common/methods/mtdUIKit";
import { DownloadFile } from "@/models";
import { downloadFilesState } from '@/vuex/store/downloadFiles';

export const getDownloadFiles = (state:downloadFilesState, requestPath:string) => {
  axios
    .get(requestPath)
    .then((response:AxiosResponse) => {
      const resData = response && response.data ? response.data : {};
      state.downloadFiles = resData.map((data:any) => new DownloadFile(data));
      state.isGettingList = false;
    })
    .catch(() => {
      ukNotify('ダウンロード可能なファイルの一覧を取得できませんでした。');
    })
}
