import axios from "@/libs/Axios";
import { AxiosResponse } from 'axios';
import { ukNotify } from "@/composables/common/common/methods/mtdUIKit";
import { Store } from 'vuex';
import { State } from '@/vuex';

interface Emits{
  (e: 'delete', id:string): void
}
export default (store:Store<State>, emit:Emits) => {
  const onClick = async (requestPath:string, optionalStatementId:number) => {
    // 支払項目データをdelete
    const deletePath = `${requestPath}/${optionalStatementId}`;
    axios
      .delete(deletePath)
      .then((_response:AxiosResponse) => {
        // storeからoptionalStatementを削除
        store.commit('optionalStatements/remove', optionalStatementId)
        // 親コンポーネントに削除したことを通知
        emit('delete', optionalStatementId.toString());
        // 削除成功メッセージ
        notifySuccess();
        return true;
      })
      .catch(() => {
        notifyError();
        return null;
      });
  }

  function notifySuccess() {
    ukNotify('支払項目を削除しました。');
  }

  function notifyError() {
    ukNotify('支払項目を削除できませんでした。');
  }

  return { onClick }
}
