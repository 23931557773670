import axios from "@/libs/Axios";
import { AxiosResponse } from 'axios';
import { ukNotify } from "@/composables/common/common/methods/mtdUIKit";
import { TempPaymentSummary } from "@/models";
import { tempPaymentSummariesState } from '@/vuex/store/tempPaymentSummaries';

interface Arguments {
  requestPath:string;
}

export const getTempPaymentSummaries = (state:tempPaymentSummariesState, args:Arguments) => {
  axios
    .get(args.requestPath, {})
    .then((response:AxiosResponse) => {
      const resData = response && response.data ? response.data : {};
      state.paymentStatementsNoRange = resData.document_no_range;
      state.paymentStatementsTerm = resData.term;
      state.tempPaymentSummaries = resData.temp_payment_summaries.map((data:any) => new TempPaymentSummary(data));
    })
    .catch(() => {
      ukNotify('支払通知書情報を取得できませんでした。');
    });
}
