<script setup lang="ts">
import mtdOnClick from '@/composables/common/panels/payment_statement_histroies/index/button_enable_redownload/methods/mtdOnClick';

import { useStore, Store } from 'vuex';
import { key } from '@/vuex';
import type { State } from '@/vuex';
const store:Store<State> = useStore(key);

interface Props {
  requestPath:string
  downloadFileId:number
}
const props = defineProps<Props>();

// == methods ==
const { onClick } = mtdOnClick(store, props.requestPath, props.downloadFileId);
</script>

<template lang="pug">
button.uk-button.uk-button-default.uk-text-nowrap(
  :id="`button_enable_redownload_${downloadFileId}`"
  type="button"
  @click="onClick"
) 再ダウンロード
</template>
