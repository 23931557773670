import BinderBase from '@/binders/binder_base';
import messageTextarea from '@/components/common/panels/sms_transmission_schedules/input_sms/message_textarea';

class MessageTextareaBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#message_textarea',
        app: messageTextarea
      }
    ];
  }
}

export default MessageTextareaBinder;