import { Ref } from "vue";

export default (editedRejectionReason:Ref<string>, originalRejectionReason:Ref<string>) => {
  const clearModalForms = () => {
    // refsをリセット
    editedRejectionReason.value = originalRejectionReason.value;
  }

  return {
    clearModalForms
  }
}
