<script setup lang="ts">
import cmpStoreState from "@/composables/common/common/computed/cmpStoreState";
import mtdOnClick from "@/composables/common/panels/items/index/approval_status/methods/mtdOnClick";
import refs from "@/composables/common/panels/items/index/approval_status/refs";

import { computed } from 'vue';
import { useStore, Store } from 'vuex';
import { key } from '@/vuex';
import type { State } from '@/vuex';
const store:Store<State> = useStore(key);

interface Props {
  itemId: number,
  approvalDate: string,
  approvalRequestDate: string,
  rejectionDate: string,
  baseUrl: string,
  href: string,
}
const props = withDefaults(defineProps<Props>(), {
  approvalDate: null
});

// == ref ==
const { approvalDate, rejectionDate } = refs(props);
// == computed ==
const { isApproveLoading } = cmpStoreState(store);
// == methods ==
const { onClick } = mtdOnClick(store, approvalDate)
</script>

<template lang="pug">
.juno-items-approved-status.juno-item-padding.uk-text-center(v-if="approvalDate")
  a.uk-link-reset(:href="href") 承認済み
.juno-items-approved-status.juno-item-padding.uk-text-center(v-else-if="rejectionDate && !approvalRequestDate")
  a.uk-link-reset(:href="href") 差し戻し
.juno-items-approved-status.juno-item-padding.uk-text-center(v-else)
  button.uk-button.uk-button-default.uk-text-nowrap(
    @click="onClick(baseUrl, itemId, approvalDate)"
    :id="`button_approve_${itemId}`"
    :disabled="isApproveLoading"
    ) 承認
</template>
