import BinderBase from '@/binders/binder_base';
import FormStores from '@/components/common/panels/coupons/form_stores';

class FormStoresBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#form_stores',
        app: FormStores
      }
    ];
  }
}

export default FormStoresBinder;