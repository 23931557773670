import { switchToggle } from '@/vuex/store/items/mutations/mutSwitchToggle';

const state = () => {
  return {
    isApproveLoading: false, 
  }
};

export interface itemsState {
  isApproveLoading: boolean,
}

export const items = {
  namespaced: true,
  state,
  mutations: {
    'switchToggle': switchToggle
  }
};
