import axios from "@/libs/Axios";
import { AxiosResponse } from 'axios';
import { ukNotify } from "@/composables/common/common/methods/mtdUIKit";
import { Inquiry } from "@/models";
import { inquiriesState } from '@/vuex/store/inquiries';

interface Arguments {
  requestPath:string;
  donationId:number
}

export const getInquiries = (state:inquiriesState, args:Arguments) => {
  const params = { donation_id: args.donationId };
  axios
    .get(args.requestPath, { params })
    .then((response:AxiosResponse) => {
      const resData = response && response.data ? response.data : {};
      state.inquiries = resData.map((data:any) => new Inquiry(data));
    })
    .catch(() => {
      ukNotify('問い合わせの一覧を取得できませんでした。');
    });
}
