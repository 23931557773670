<script setup lang="ts">
import refs from "@/composables/common/panels/sms_transmission_schedules/input_sms/message_textarea/refs";

const emit = defineEmits(['update:message']);

const onInput = (event: any) => {
  state.count = event.target.value.length;
  state.isWithinCharacterLimit = event.target.value.length <= 70;
  emit('update:message', message);
}
// == ref ==
const { message, state } = refs();

</script>

<template lang="pug">
.uk-flex.uk-flex-between
  div
    label.uk-form-label 本文
    span.uk-label-danger.juno-required-label 必須
  label 現在の文字数：
    span(:class="{ 'uk-text-danger': !state.isWithinCharacterLimit }") {{ state.count }}文字
textarea.uk-input.uk-form-width-auto.juno-text-area-l-vertical(
  v-model="message"
  id="sms_transmission_schedule_message"
  name="sms_transmission_schedule[message]"
  @input="onInput"
)
</template>
