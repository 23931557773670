import { computed } from "vue";
import { Store } from 'vuex';
import type { State } from '@/vuex';

export default (store:Store<State>) => {
  return {
    // 支払項目・日付の昇順＆IDの昇順でソート
    optionalStatements: computed(() => {
      return store.state.optionalStatements.optionalStatements.sort((a, b) => {
        const diffRecordedDate =  a.recordedDate.diff(b.recordedDate);
        if (diffRecordedDate) return diffRecordedDate;

        return (a.id || 0) - (b.id || 0); // idはundefinedを許可しているので、0に変換してから比較
      });
    })
  }
}
