<script setup lang="ts">
import refs from "@/composables/common/panels/coupons/form_stores/refs";

interface Props {
  allShopsFlag: boolean;
  storesByPrefecture: any;
  storeIds: Array<Number>;
}
const props = defineProps<Props>();
// == ref ==
const { allShopsFlag, storesByPrefecture, storeIds } = refs(props);
</script>

<template lang="pug">
.uk-margin-small-bottom
  .form-group.uk-flex
    .uk-form-horizontal.juno-form-height
      .uk-form-label.uk-flex
        label.uk-flex 全店舗対象
        span.uk-label-danger.juno-required-label 必須
      .uk-form-controls.juno-radio-buttons.uk-flex
        label.juno-radio-button
          input(type="radio" :value="true" key="allShopsFlag" v-model="allShopsFlag" name="coupon[all_shops_flag]" id="coupon_all_shops_flag_true")
          | 対象
        label.juno-radio-button
          input(type="radio" :value="false" key="allShopsFlag" v-model="allShopsFlag" name="coupon[all_shops_flag]" id="coupon_all_shops_flag_false")
          | 非対象
.uk-margin-small-bottom(v-if="!allShopsFlag")
  .form-group
    .uk-form-label.uk-flex
      label.uk-flex 発行対象店舗
      span.uk-label-danger.juno-required-label 必須
    .uk-padding-small
      div(v-for="(stores, prefecture) in storesByPrefecture" :key="prefecture")
        .uk-text-small {{ prefecture }}
        .uk-padding-small
          div(v-for="store in stores" :key="store[0]")
            input(type='checkbox', name="coupon[store_ids][]", :id="`coupon_store_ids_${store[0]}`", :value="store[0]" :checked="storeIds.includes(store[0])")
            label.uk-form-label {{ store[1]}}
</template>