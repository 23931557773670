<script setup lang="ts">
import mtdOnInput from "@/composables/common/input_forms/form_number/methods/mtdOnInput";

interface Props {
  labelText: string; // 入力フォームのラベルテキスト
  classNames: string; // inputタグに付与するclass名（半角スペース区切り）
  modelName: string; // 自model名
  columnName: string; // カラム名
  modelValue?: string; // 入力文字列
  noteText?: string; // 単位、備考などのテキスト
  requiredLabel?: boolean; // 必須ラベルの表示
  disabled?: boolean; // disabledかどうか
  placeholder?: string; // プレースホルダー
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  noteText: '',
  requiredLabel: false,
  disabled: false
});
const emit = defineEmits(['update:modelValue']);
const { onInput } = mtdOnInput(emit);
</script>

<template lang="pug">
.form-group.uk-flex
  .uk-form-horizontal.uk-margin-small-right
    label.uk-form-label.uk-flex
      |  {{ labelText }}
      span.uk-label-danger.juno-required-label(v-if="requiredLabel") 必須
    .uk-form-controls.uk-flex
      input.uk-input(
        v-model="modelValue"
        :id="`${modelName}_${columnName}`"
        :name="`${modelName}[${columnName}]`"
        :class="classNames"
        :disabled="disabled"
        :placeholder="placeholder"
        @input="onInput"
        type="text"
      )
      .juno-form-note.uk-text-small.uk-text-muted.uk-padding-remove-left(v-if="noteText")
        span.uk-text-middle.uk-margin-small-left {{noteText}}
</template>
