import { getOptionalStatements } from '@/vuex/store/optionalStatements/mutations/mutGetOptionalStatements';
import { push } from '@/vuex/store/optionalStatements/mutations/mutPush';
import { remove } from '@/vuex/store/optionalStatements/mutations/mutRemove';
import { splice } from '@/vuex/store/optionalStatements/mutations/mutSplice';
import { OptionalStatement } from "@/models";

const state = () => {
  return {
    optionalStatements: [], // 追加支払項目（仮登録）
  }
};

export interface optionalStatementsState {
  optionalStatements: OptionalStatement[],
}

export const optionalStatements = {
  namespaced: true,
  state,
  mutations: {
    'getOptionalStatements': getOptionalStatements,
    'push': push,
    'remove': remove,
    'splice': splice
  }
};
