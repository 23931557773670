import { Ref } from 'vue';
import { Store } from 'vuex';
import type { State } from '@/vuex';
import { computed } from "vue";
import cmpStoreState from "@/composables/common/common/computed/cmpStoreState";

export default (store:Store<State>, targetFileId:Ref<number>) => {
  const { downloadFiles } = cmpStoreState(store);
  return {
    targetFile: computed(() => downloadFiles.value.find(file => file.id === targetFileId.value))
  }
}
