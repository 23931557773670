import { ref } from "vue";

export default (props:any) => {
  const selectAmountSettingOptions = ref(props.selectAmountSettingOptions);
  const amountSetting = ref(props.amountSetting);
  const separateDonationAmount = ref(props.separateDonationAmount);

  return {
    selectAmountSettingOptions,
    amountSetting,
    separateDonationAmount,
  };
};