import { Store } from 'vuex';
import { State } from '@/vuex';

export default (store:Store<State>, requestPath:string) => {
  const getPaymentStatementSetting = () => {
    const params = {
      requestPath
    };
    store.commit('paymentStatementSetting/getPaymentStatementSetting', params);
  }

  return { getPaymentStatementSetting }
}
