import { ref } from "vue";

export default (props:any) => {
	const selectedInvoiceFlag = ref(props.invoiceFlag);
	const eligibleBusinessNumber = ref(props.eligibleBusinessNumberValue);
	const selectedRoundingTax = ref(props.roundingTaxIdValue);

  return {
		selectedInvoiceFlag,
		eligibleBusinessNumber,
		selectedRoundingTax
  };
};
