import BinderBase from '@/binders/binder_base';
import FormRestrictedDonationUseInfo from '@/components/common/panels/store_settings/form_restricted_donation_use_info';

class FormRestrictedDonationUseInfoBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#form_restricted_donation_use_info',
        app: FormRestrictedDonationUseInfo
      }
    ];
  }
}

export default FormRestrictedDonationUseInfoBinder;