<script setup lang="ts">
import ModalInquiry from "@/components/common/panels/donations/edit/modal_inquiry.vue";

interface Props {
  labelText:string,
  requestPath:string,
  donationId:number
}
const props = defineProps<Props>();

// 問い合わせ履歴一覧のtable要素のid
const modalIdKey = 'add_inquiry_modal';
</script>

<template lang="pug">
div
  button#button_add_inquiry.uk-button.uk-button-default(:uk-toggle="`target: #${modalIdKey}`" type="button" @click="clearForms")
    span.uk-text-middle {{labelText}}

  ModalInquiry(:id-key="modalIdKey" :request-path="requestPath" :donation-id="donationId")
</template>
