<script setup lang="ts">
interface Props {
  labelText: string; // 入力フォームのラベルテキスト
  modelName: string; // 自model名
  columnName: string; // 自model名
  list: any[]; // セレクトボックスに表示するリスト（[名称, id]の配列）
  modelValue?: string[]; // 選択されているidの配列
  requiredLabel?: boolean; // 必須ラベルの表示
  disabled?: boolean; // disabledかどうか
}
const props = withDefaults(defineProps<Props>(), {
  requiredLabel: false,
  disabled: false
});

const emit = defineEmits(['update:modelValue']);
const onChange = () => emit('update:modelValue', props.modelValue);
</script>

<template lang="pug">
.form-group
  .uk-form-horizontal.uk-margin-small-right
    label.uk-form-label.uk-flex
      | {{ labelText }}
      span.uk-label-danger.juno-required-label(v-if="requiredLabel") 必須
    .uk-form-controls
      .input.select
        select.is-valid.select.uk-select.uk-form-width-medium(
          multiple
          v-model="modelValue"
          :id="`${modelName}_${columnName}`"
          :name="`${modelName}[${columnName}]`"
          :disabled="disabled"
          @change="onChange"
        )
          option.uk-text-muted(v-for="target in list" :value="target[1]") {{target[0]}}
</template>
