<script setup lang="ts">
import { watch } from 'vue';
import refs from "@/composables/common/panels/items/common/form_calc_donation_amount/refs";

interface Props {
  disabled?: boolean,
  returnRate: Number,
  price: Number,
  donationAmount: Number
}
const props = withDefaults(defineProps<Props>(), { disabled: false });

// == ref ==
const { returnRate, price, donationAmount } = refs(props);

// == watch
watch([returnRate, price], ([newVal1, newVal2]) => {
  const newReturnRate = parseFloat(newVal1);
  const newPrice = parseFloat(newVal2);
  if (!isNaN(newReturnRate) && !isNaN(newPrice) && newReturnRate !== 0) {
    donationAmount.value = newPrice / (newReturnRate / 100);
  } else {
    // どちらかの値が数値でない場合、donationAmountをリセット
    donationAmount.value = '';
  } ;
});
</script>

<template lang="pug">
.uk-margin-small-bottom
  .form-group.uk-flex
    .uk-form-horizontal
      .uk-form-label.uk-flex
        label 返礼率
        span.uk-label-danger.juno-required-label 必須
      .uk-form-controls
        input.uk-input.uk-form-width-medium(
          v-model="returnRate"
          name="item[return_rate]"
          id="item_return_rate"
          :disabled="disabled"
          )
.uk-margin-small-bottom
  .form-group.uk-flex
    .uk-form-horizontal
      .uk-form-label.uk-flex
        label 商品金額
        span.uk-label-danger.juno-required-label 必須
      .uk-form-controls
        input.uk-input.uk-form-width-medium(
          v-model="price"
          name="item[price]"
          id="item_price"
          :disabled="disabled"
          )
.uk-margin-small-bottom
  .form-group.uk-flex
    .uk-form-horizontal
      .uk-form-label.uk-flex
        label 寄附金額（自動入力）
        span.uk-label-danger.juno-required-label 必須
      .uk-form-controls
        input.uk-input.uk-form-width-medium.juno-readonly-form-disabled(
          v-model="donationAmount"
          name="item[donation_amount]"
          id="item_donation_amount"
          readonly
          )
</template>
