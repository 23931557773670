<script setup lang="ts">
import refs from "@/composables/common/panels/customer_surveys/customer_survey_choice_forms/refs";
import cmpDisabled from "@/composables/common/panels/customer_surveys/customer_survey_choice_forms/computed/cmpDisabled";
import mtdClickAdd from "@/composables/common/panels/customer_surveys/customer_survey_choice_forms/methods/mtdClickAdd";
import mtdClickDelete from "@/composables/common/panels/customer_surveys/customer_survey_choice_forms/methods/mtdClickDelete";
import { onMounted } from 'vue';
import CustomerSurveyChoiceForm from "@/components/common/panels/customer_surveys/customer_survey_choice_form.vue";

interface Props {
  customerSurveyChoices: Array<any> //パラメーターのデータ
}
const props = defineProps<Props>();
// // == ref ==
let { existDataLastOrder } = refs(props);
const { countClick, formDataOrders, formDatas } = refs(props);
// == methods ==
const { clickAdd } = mtdClickAdd(formDatas, props.customerSurveyChoices, countClick, formDataOrders, existDataLastOrder);
const { clickDelete } = mtdClickDelete(formDataOrders, existDataLastOrder, formDatas);
// == computed ==
// フォームが一つの時は削除ボタンを非活性化、複数ある場合はボタン活性化
const { disabled } = cmpDisabled(formDatas)

onMounted (() => {
  // 既存のフォーム数に基づき配列に不変の数値を付与
  for (let i = 0; i < props.customerSurveyChoices.length; i++ ){
    formDataOrders.push(i)
  }
  // 不変の配列最後の数値を代入
  existDataLastOrder.value = formDataOrders[formDataOrders.length - 1];
})
</script>

<template lang="pug">
div.uk-position-relative(v-for="(formData, index) in formDatas" :key="formData")
  CustomerSurveyChoiceForm(:index="index", :formData="formData", :formDataOrders="formDataOrders")
  .uk-position-right
    button.uk-button.uk-button-default(
      type="button",
      @click="clickDelete(index)", 
      :id="`customer_survey_choices_delete_${formDataOrders[index]}`",
      :disabled="disabled"
      )
      span(uk-icon="minus-circle")
      span.uk-text-middle 削除
.uk-flex.uk-flex-center.uk-margin-small-top
  .uk-margin-small-right
    button.uk-button.uk-button-default(
      type="button",
      @click="clickAdd",
      id="customer_survey_choices_add"
      )
      span(uk-icon="plus-circle")
      span.uk-text-middle 選択肢追加
</template>
