<script setup lang="ts">
import refs from "@/composables/common/input_forms/form_select_month_date/refs";
import selectableDays from "@/composables/common/input_forms/form_select_month_date/computed/selectableDays";
import resetDate from "@/composables/common/input_forms/form_select_month_date/watch/resetDate";

interface Props {
  labelText: string; // 入力フォームのラベルテキスト
  modelName: string; // 自model名
  monthColumnName: string; // monthカラム名
  dateColumnName: string; // dateカラム名
  monthValue: number;
  dateValue: number;
  requiredLabel?: boolean; // 必須ラベルの表示
}
const props = withDefaults(defineProps<Props>(), {
  disabled: false
});
const selectableMonths = [...Array(12)].map((_, i) => i + 1)
// == refs ==
const { selectedMonth, selectedDate } = refs(props.monthValue, props.dateValue);
// == computed ==
const selectableDates = selectableDays(selectedMonth);
// == watch ==
resetDate(selectedDate, selectableDates);
</script>

<template lang="pug">
.form-group
  .uk-form-horizontal.uk-margin-small-right
    label.uk-form-label.uk-flex
      | {{ labelText }}
      span.uk-label-danger.juno-required-label(v-if="requiredLabel") 必須
    .uk-form-controls.uk-flex.uk-flex-middle
      .uk-margin-small-right 翌年
      .input.select.uk-margin-small-right
        select.is-valid.select.uk-select.uk-form-width-xsmall(
          v-model="selectedMonth"
          :id="`${modelName}_${monthColumnName}`"
          :name="`${modelName}[${monthColumnName}]`"
        )
          option.uk-text-muted(v-for="target in selectableMonths" :value="target") {{ target }}
      label.uk-margin-small-right 月
      .input.select.uk-margin-small-right
        select.is-valid.select.uk-select.uk-form-width-xsmall(
          v-model="selectedDate"
          :id="`${modelName}_${dateColumnName}`"
          :name="`${modelName}[${dateColumnName}]`"
          :disabled="!selectedMonth"
        )
          option.uk-text-muted(v-for="target in selectableDates" :value="target") {{ target }}
      label 日
</template>