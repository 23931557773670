import { computed, Ref } from "vue"

export default (payTypeList: Array<any>, payTypeGroupId: Ref<number|string|null>) => {
  return {
    groupedPayTypes: computed(() => {
      if (payTypeGroupId.value === '') return payTypeList;

      return payTypeList.filter(payType => payType[2] === payTypeGroupId.value);
    })
  }
}
