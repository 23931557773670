import BinderBase from '@/binders/binder_base';
import CustomerSurveyChoiceForms from '@/components/common/panels/customer_surveys/customer_survey_choice_forms';

class CustomerSurveyChoiceFormsBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#customer_survey_choice_forms',
        app: CustomerSurveyChoiceForms
      }
    ];
  }
}

export default CustomerSurveyChoiceFormsBinder;
