import { ref } from "vue";

export default (confirmedTopicIdValue:number|null) => {
  // 確認済み
  const confirmed = ref(Boolean(confirmedTopicIdValue));
  // 確認済みお知らせID
  const confirmedTopicId = ref(confirmedTopicIdValue);

  return {
    confirmed,
    confirmedTopicId
  };
};
