<script setup lang="ts">
import refs from "@/composables/common/panels/payment_statements/donations/index/modal_add_optional_statements/refs";
import mtdGetOptionalStatements
  from "@/composables/common/panels/payment_statements/donations/index/modal_add_optional_statements/methods/mtdGetOptionalStatements";
import mtdOnClear
  from "@/composables/common/panels/payment_statements/donations/index/modal_add_optional_statements/methods/mtdOnClear";
import mtdOnClickRow
  from "@/composables/common/panels/payment_statements/donations/index/modal_add_optional_statements/methods/mtdOnClickRow";
import ButtonCreateOptionalStatement from "./button_create_optional_statement.vue";
import ButtonClearOptionalStatement from "./button_clear_optional_statement.vue";
import ButtonUpdateOptionalStatement from "./button_update_optional_statement.vue";
import FormDate from "@/components/common/input_forms/form_date.vue";
import FormLabel from "@/components/common/input_forms/form_label.vue";
import FormNumber from "@/components/common/input_forms/form_number.vue";
import FormSelect from "@/components/common/input_forms/form_select.vue";
import FormSelectMulti from "@/components/common/input_forms/form_select_multi.vue";
import FormText from "@/components/common/input_forms/form_text.vue";
import TableOptionalStatements from "./table_optional_statements.vue";

import { useStore, Store } from 'vuex';
import { key } from '@/vuex';
import type { State } from '@/vuex';
const store:Store<State> = useStore(key);

interface Props {
  requestPath:string
  storeCollection: any[] // セレクトボックスに表示する店舗配列（[名称, id]の配列）
}
const props = withDefaults(defineProps<Props>(), {});

// 選択可能な消費税
const taxCollection = [['', ''], ['8%', '8.0'], ['10%', '10.0']];

// == ref ==
const { id, storeIds, recordedDate, name, amount, taxRate } = refs();

// == methods ==
const { getOptionalStatements } = mtdGetOptionalStatements(store);
const { onClear } = mtdOnClear(id, storeIds, recordedDate, name, amount, taxRate);
const { onClickRow } = mtdOnClickRow(id, storeIds, recordedDate, name, amount, taxRate);
const onDelete = (deletedId:string) => { if (deletedId === id.value) onClear(); };

getOptionalStatements(props.requestPath);
</script>

<template lang="pug">
.uk-modal-dialog.uk-modal-body
  button.uk-modal-close-default(type="button" uk-close)
  div
    h4.uk-margin-medium-bottom {{`支払項目の追加・編集`}}
  .uk-section.uk-section-muted.uk-padding-small.uk-margin-small-bottom
    .uk-grid(class="uk-child-width-1-1@m uk-child-width-1-2@l")
      div
        .uk-margin-small-bottom
          FormLabel(v-if="!id" label-text="ID" label-value=" 新規追加します。")
          FormText(
            v-else
            v-model="id"
            label-text="ID"
            model-name="optional_statement"
            column-name="id"
            class-names="juno-form-width-sm"
            :disabled="true"
          )
        .uk-margin-small-bottom
          FormSelectMulti(
            v-model="storeIds"
            label-text="対象店舗"
            place-holder="全店舗"
            model-name="optional_statement"
            column-name="store_ids"
            :list="storeCollection"
            :required-label="true"
          )
      div
        .uk-margin-small-bottom
          FormDate(
            v-model="recordedDate"
            label-text="計上日"
            model-name="optional_statement"
            column-name="recorded_date"
            :model-value="recordedDate"
            :modal-form="true"
            :disabled="false"
            :required-label="true"
          )        .uk-margin-small-bottom
        .uk-margin-small-bottom
          FormText(
            v-model="name"
            label-text="項目名"
            model-name="optional_statement"
            column-name="name"
            class-names="juno-form-width-m"
            :required-label="true"
          )
        .uk-margin-small-bottom
          FormNumber(
            v-model="amount"
            label-text="金額（税込）"
            model-name="optional_statement"
            column-name="amount"
            class-names="juno-form-width-sm"
            :required-label="true"
            note-text="円"
            placeholder="半角整数(カンマ無し)"
          )
        FormSelect(
          v-model="taxRate"
          label-text="消費税率"
          place-holder=""
          model-name="optional_statement"
          column-name="tax_rate"
          :list="taxCollection"
          :required-label="true"
          class-names="uk-form-width-xsmall"
        )
  .uk-flex.uk-flex-right.uk-margin-medium-bottom
    .uk-margin-small-right
      ButtonClearOptionalStatement(
        v-model:id="id"
        v-model:storeIds="storeIds"
        v-model:recorded-date="recordedDate"
        v-model:name="name"
        v-model:amount="amount"
        v-model:tax-rate="taxRate"
        @clear="onClear"
      )
    .uk-margin-small-right
      ButtonCreateOptionalStatement(
        v-if="!id"
        key="create"
        :request-path="requestPath"
        v-model:store-ids="storeIds"
        v-model:recorded-date="recordedDate"
        v-model:name="name"
        v-model:amount="amount"
        v-model:tax-rate="taxRate"
      )
      ButtonUpdateOptionalStatement(
        v-else
        key="update"
        :request-path="requestPath"
        v-model:optional-statement-id="id"
        v-model:store-ids="storeIds"
        v-model:recorded-date="recordedDate"
        v-model:name="name"
        v-model:amount="amount"
        v-model:tax-rate="taxRate"
      )
  hr
  div
    TableOptionalStatements(:request-path="requestPath" @click-row="onClickRow" @delete="onDelete")
</template>
