import axios from "@/libs/Axios";
import { AxiosResponse } from 'axios';
import UIkit from 'uikit';
import { ukNotify } from "@/composables/common/common/methods/mtdUIKit";
import { Ref } from "vue";
import mtdFormatDate from "@/composables/common/common/methods/mtdFormatDate";

export default (disabled:Ref<boolean>) => {
  const onClick = async (requestPath:string, itemId:number, editedRejectionReason:string) => {
    disabled.value = true;
    // itemをupdate
    const updatePath = `${requestPath}/${itemId}`
    axios
      .put(updatePath, updateParams(itemId, editedRejectionReason))
      .then((response:AxiosResponse) => {
        const resData = response && response.data ? response.data : {};
        if (resData?.id) {
          closeModal();
          setPanelDate(resData.rejection_reason);
        } else {
          notifyError();
        }
        return true;
      })
      .catch(() => {
        notifyError();
        return null;
      });
  }

  function updateParams (itemId:number, editedRejectionReason:string) {
    const { formatDate } = mtdFormatDate();
    return {
      'item': {
        'id': itemId,
        'approval_request_date': null,
        'rejection_date': formatDate(new Date),
        'rejection_reason': editedRejectionReason
      }
    };
  }

  function notifyError() {
    ukNotify('差し戻し処理に問題が発生しました。');
  }

  function closeModal() {
    const element = document.getElementById('rejection_modal');
    UIkit.modal(element)?.hide();
    ukNotify('差し戻しました。');
  }

  function setPanelDate(resRejectionReason:string) {
    const { formatDate } = mtdFormatDate();
    const approvalButton = document.getElementById('item_approval') as HTMLButtonElement;
    approvalButton.disabled = true;
    const relectionButton = document.getElementById('item_rejection') as HTMLButtonElement;
    relectionButton.disabled = true;
    const approvalRequestDate = document.getElementsByName('item[approval_request_date]')[0] as HTMLInputElement;
    approvalRequestDate.value = '';
    const rejectionDate = document.getElementsByName('item[rejection_date]')[0] as HTMLInputElement;
    rejectionDate.value = formatDate(new Date);
    const rejectionReason = document.getElementById('item_rejection_reason') as HTMLInputElement;
    rejectionReason.value = resRejectionReason;
  }

  return { onClick }
}
