<script setup lang="ts">
import { ref } from 'vue';

interface Props {
  disabled?: boolean,
  buttonName: string,
  buttonText: string, // ラベルテキスト
  userId: number,
  buttonId: string
}
const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  buttonId: null
});

// == ref ==
const isClicked = ref(false);
// == methods ==
const onClick = () => { isClicked.value = true; };
</script>

<template lang="pug">
div(v-if="isClicked")
  input(
    :name="`item[${buttonName}]`"
    :value="new Date()"
    type="hidden"
  )
div(v-if="isClicked && buttonName === 'approval_request_date'")
  input(
    name="item[user_id]"
    :value="userId"
    type="hidden"
  )
button.uk-button.uk-button-default.uk-text-nowrap(
  :id="buttonId"
  :disabled="disabled"
  @click="onClick"
  ) {{ buttonText }}
</template>
