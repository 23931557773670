import { Ref } from "vue";
import UIkit from 'uikit';
import { Inquiry } from "@/models";

export default (tableIdKey:string, selectedInquiry:Ref<Inquiry|null>) => {
  const openModal = (inquiry:Inquiry) => {
    // 選択した問い合わせのinquiryIdをmodal用にセット
    selectedInquiry.value = inquiry;

    // 更新用にmodalを開く
    const element = document.getElementById(tableIdKey);
    UIkit.modal(element).show();
  }

  return { openModal }
}
