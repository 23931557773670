import BinderBase from '@/binders/binder_base';
import ButtonIssuePaymentStatement from '@/components/common/panels/payment_statements/donations/index/button_issue_payment_statement';

class ButtonIssuePaymentStatementBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#btn_issue_payment_statement',
        app: ButtonIssuePaymentStatement
      }
    ];
  }
}

export default ButtonIssuePaymentStatementBinder;
