import BinderBase from '@/binders/binder_base';
import FormDateRange from '@/components/common/search_forms/form_date_range';

class FormDateRangeBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#form_date_range_donated_at',
        app: FormDateRange
      },
      {
        el: '#form_date_range_canceled_at',
        app: FormDateRange
      },
      {
        el: '#form_date_range_issue_date',
        app: FormDateRange
      },
      {
        el: '#form_date_range_created_at',
        app: FormDateRange
      },
      {
        el: '#form_date_range_updated_at',
        app: FormDateRange
      },
      {
        el: '#form_date_range_personal_info_input_at',
        app: FormDateRange
      },
      {
        el: '#form_date_range_personal_info_updated_at',
        app: FormDateRange
      },
      {
        el: '#form_date_range_paid_at',
        app: FormDateRange
      },
      {
        el: '#form_date_range_send_at',
        app: FormDateRange
      },
      {
        el: '#form_date_range_issued_at',
        app: FormDateRange
      },
      {
        el: '#form_date_range_settlemented_at',
        app: FormDateRange
      },
      {
        el: '#form_date_range_distribution_started_at',
        app: FormDateRange
      },
      {
        el: '#form_date_range_distribution_ended_at',
        app: FormDateRange
      },
      {
        el: '#form_date_range_expired_at',
        app: FormDateRange
      }
    ];
  }
}

export default FormDateRangeBinder;
