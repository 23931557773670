import { DateTime } from 'luxon';

const DataDiv = {
  DONATIONS: 'donations',
  PAYMENT_STATEMENTS: 'payment_statements',
} as const;
type DataDivType = typeof DataDiv[keyof typeof DataDiv];

export class DownloadFile {
  static EXTENSION_CSV = 'csv';
  static STATUS_GENERATING = 'generating';
  static STATUS_FINISHED = 'finished';
  static TIMEOUT_SECONDS = 300;

  private _id:number = 0;
  private _dataDiv:DataDivType|null = null;
  private _extension:string = '';
  private _status:string|null = null;
  private _fileName:string = '';
  private _fileUrl:string = '';
  private _downloadStartedAt:DateTime|null = null;
  private _createdAt:DateTime|null = null;

  constructor(private _orgInstance: any|null) {
    if (_orgInstance) {
      this._id = _orgInstance.id;
      this._dataDiv = _orgInstance.data_div;
      this._extension = _orgInstance.extension;
      this._status = _orgInstance.status;
      this._fileName = _orgInstance.file_name;
      this._fileUrl = _orgInstance.file_url;
      this._downloadStartedAt = DateTime.fromISO(_orgInstance.download_started_at);
      this._createdAt = DateTime.fromISO(_orgInstance.created_at);
    }
  }

  // getters
  get id () { return this._id; }
  get dataDiv () { return this._dataDiv; }
  get extension () { return this._extension; }
  get fileName () { return this._fileName; }
  get fileUrl () { return this._fileUrl; }

  // DateTime型(luxon)のカラム
  get downloadStartedAt () { return this._downloadStartedAt; }
  get createdAt () { return this._createdAt; }

  // 'YYYY-MM-DD hh:mm:ss'フォーマット文字列
  get downloadStartedAtSqlFormat () { return this._downloadStartedAt?.toFormat('yyyy-MM-dd TT'); }
  get createdAtSqlFormat () { return this._createdAt?.toFormat('yyyy-MM-dd TT'); }

  // ファイル生成中か
  get isGenerating () { return this._status === DownloadFile.STATUS_GENERATING; }

  // ファイル生成完了か
  get isFinished () { return this._status === DownloadFile.STATUS_FINISHED; }

  // ファイル生成中か（文字列）
  get isGeneratingText () { return this.isGenerating ? 'ダウンロード中' : ''; }

  // タイムアウトかどうか
  get isTimeout () {
    return this._createdAt.plus({ seconds: DownloadFile.TIMEOUT_SECONDS }).diffNow('seconds').seconds < 0;
  }

  // setters
  set dataDiv (val) {
    this._dataDiv = val;
  }
  set extension (val) { this._extension = val; }
}
