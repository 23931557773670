import { OptionalStatement } from "@/models";

interface Emits {
  (e: 'click-row', optionalStatement:OptionalStatement): void
  (e: 'delete', id:string): void
}
export default (emit:Emits) => {
  const onDelete = (optionalStatement:OptionalStatement) => {
    emit('delete', (optionalStatement.id || 0).toString());
  }

  return { onDelete }
}
