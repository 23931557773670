import BinderBase from '@/binders/binder_base';
import FormCategory from '@/components/common/panels/shared_files/form_category';

class FormCategoryBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#form_category',
        app: FormCategory
      }
    ];
  }
}

export default FormCategoryBinder;
