import { ukConfirm } from "@/composables/common/common/methods/mtdUIKit";

const addUkConfirmListener = () => {
  document.body.addEventListener('confirm', (event) => {
    event.preventDefault(); // デフォルトの確認ダイアログを表示しない
    const el = event.target as HTMLButtonElement;

    // 確認ダイアログでOK押下時にconfirm属性を削除するので、confirm属性がない場合はダイアログを表示しない
    if (!el.dataset.confirm) return;

    const message = el.dataset.confirm;
    ukConfirm(message)
      .then(() => {
        // confirm属性を削除した上で、再度ボタンクリックイベントを発火
        if (el.dataset) delete el.dataset.confirm;
        el.click();
      })
      .catch(() => {
        // 何もしない
      });
    return true;
  });
}

export { addUkConfirmListener };
