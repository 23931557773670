import { OptionalStatement } from "@/models";

interface Emits {
  (e: 'click-row', optionalStatement:OptionalStatement): void
  (e: 'delete', id:string): void
}
export default (emit:Emits) => {
  const onClickRow = (optionalStatement:OptionalStatement) => {
    emit('click-row', optionalStatement);
  }

  return { onClickRow }
}
