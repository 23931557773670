<script setup lang="ts">
interface Props {
  requestPath: string;
  labelText: string;
}
const props = defineProps<Props>();

const redirectToRequestPath = () => {
  window.location.href = props.requestPath;
}
</script>
<template lang="pug">
button.uk-button.uk-button-default(
  type="button"
  @click="redirectToRequestPath"
) {{props.labelText}}
</template>
