import BinderBase from '../../binder_base';
import ButtonRandomPassword from '../../../components/common/buttons/button_random_password';

class ButtonRandomPasswordBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#button_random_password',
        app: ButtonRandomPassword
      }
    ];
  }
}

export default ButtonRandomPasswordBinder;
