<script setup lang="ts">
import mtdGetPaymentStatementSetting
  from "@/composables/common/panels/payment_statements/donations/index/button_issue_payment_statement/methods/mtdGetPaymentStatementSetting";
import mtdGetTempPaymentSummaries
  from "@/composables/common/panels/payment_statements/donations/index/button_issue_payment_statement/methods/mtdGetTempPaymentSummaries";

import { useStore, Store } from 'vuex';
import { key } from '@/vuex';
import type { State } from '@/vuex';
const store:Store<State> = useStore(key);

interface Props {
  paymentStatementSettingsPath:string
  tempPaymentSummariesPath:string
  labelText:string
  badgeLabel:string
}
const props = withDefaults(defineProps<Props>(), {});

const { getPaymentStatementSetting } = mtdGetPaymentStatementSetting(store, props.paymentStatementSettingsPath);
const { getTempPaymentSummaries } = mtdGetTempPaymentSummaries(store, props.tempPaymentSummariesPath);
const loadStatementsInfo = () => {
  getPaymentStatementSetting();
  getTempPaymentSummaries();
}
</script>

<template lang="pug">
button#button_issue_payment_statement.uk-button.uk-button-default(
  uk-toggle="target: #confirm_issue_payment_statement"
  type="button"
  @click="loadStatementsInfo"
)
  span.uk-text-middle {{labelText}}
    span.uk-badge.juno-button-badge {{badgeLabel}}
</template>
