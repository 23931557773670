import BinderBase from '../../binder_base';
import FormSelectMonthDate from '../../../components/common/input_forms/form_select_month_date';

class FormSelectMonthDateBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#form_month_date_hope_one_stop_deadline',
        app: FormSelectMonthDate
			},
      {
        el: '#form_month_date_personal_info_input_deadline',
        app: FormSelectMonthDate
      }
    ];
  }
}

export default FormSelectMonthDateBinder;
