<script setup lang="ts">
import mtdOnClick
  from "@/composables/common/panels/payment_statements/donations/index/button_delete_optional_statement/methods/mtdOnClick";

import { useStore, Store } from 'vuex';
import { key } from '@/vuex';
import type { State } from '@/vuex';
const store:Store<State> = useStore(key);

interface Props {
  requestPath:string,
  optionalStatementId:number,
}
const props = defineProps<Props>();

const emit = defineEmits(['delete']);

const { onClick } = mtdOnClick(store, emit);
</script>

<template lang="pug">
button#button_delete_inquiry.uk-button.uk-button-default.uk-button-small(
  type="button"
  @click="onClick(requestPath, optionalStatementId)"
) 削除
</template>
