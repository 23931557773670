import axios from "@/libs/Axios";
import { AxiosResponse } from 'axios';
import { ukNotify } from "@/composables/common/common/methods/mtdUIKit";
import { OptionalStatement } from "@/models";
import { optionalStatementsState } from '@/vuex/store/optionalStatements';

interface Arguments {
  requestPath:string;
}

export const getOptionalStatements = (state:optionalStatementsState, args:Arguments) => {
  axios
    .get(args.requestPath, {})
    .then((response:AxiosResponse) => {
      const resData = response && response.data ? response.data : {};
      state.optionalStatements = resData.map((data:any) => new OptionalStatement(data));
    })
    .catch(() => {
      ukNotify('支払項目の一覧を取得できませんでした。');
    });
}
