const router = (controller, action, callback) => {
  if (!controllerIs(controller) || !actionIs(action)) return;

  return callback();
};

function controllerIs(controller) {
  const currentController = window.routing.controller;
  return currentController === controller || (Array.isArray(controller) && controller.includes(currentController));
}

function actionIs(action) {
  const currentAction = window.routing.action;
  return currentAction === action || (Array.isArray(action) && action.includes(currentAction))
}
export default router;
