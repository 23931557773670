<script setup lang="ts">
import mtdClearSearch from "@/composables/common/common/methods/mtdClearSearch";
import mtdSetDonatedAt from "@/composables/payment_statements/donations/psd_button_clear_search/methods/mtdSetDonatedAt";

interface Props {
  buttonText: string; // ラベルテキスト
  donatedAtGteq: string; // デフォルトの寄附申込日・開始
  donatedAtLteq: string; // デフォルトの寄附申込日・終了
}
const props = defineProps<Props>();

// == methods ==
const { clearSearch } = mtdClearSearch();
const { setDonatedAt } = mtdSetDonatedAt(props.donatedAtGteq, props.donatedAtLteq);
</script>

<template lang="pug">
button.uk-button.uk-button-default.uk-text-nowrap(@click.prevent="clearSearch(); setDonatedAt();") {{ buttonText }}
</template>
