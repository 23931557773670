import { ref } from "vue";

export default (props:any) => {
  const returnRate = ref(props.returnRate);
  const price = ref(props.price);
  const donationAmount = ref(props.donationAmount);

  return {
    returnRate,
    price,
    donationAmount
  };
};
