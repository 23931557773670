import BinderBase from '@/binders/binder_base';
import FormDate from '@/components/common/input_forms/form_date';

class FormDateBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#form_date_birthday',
        app: FormDate
      },
      {
        el: '#form_date_application_start_date',
        app: FormDate
      },
      {
        el: '#form_date_application_end_date',
        app: FormDate
      },
      {
        el: '#form_date_approval_request_date',
        app: FormDate
      },
      {
        el: '#form_date_approval_date',
        app: FormDate
      },
      {
        el: '#form_date_rejection_date',
        app: FormDate
      }
    ];
  }
}

export default FormDateBinder;
