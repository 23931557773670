import BinderBase from '@/binders/binder_base';
import FormThumbnail from '@/components/common/panels/shared_files/form_thumbnail';

class FormThumbnailBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#form_thumbnail',
        app: FormThumbnail
      }
    ];
  }
}

export default FormThumbnailBinder;
