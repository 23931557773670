<script setup lang="ts">
import cmpOptionalStatements
  from "@/composables/common/panels/payment_statements/donations/index/common/computed/cmpOptionalStatements";
import mtdOnClickRow
  from "@/composables/common/panels/payment_statements/donations/index/table_optional_statements/methods/mtdOnClickRow";
import mtdOnDelete
  from "@/composables/common/panels/payment_statements/donations/index/table_optional_statements/methods/mtdOnDelete";
import TableData from "@/components/common/panels/table_data.vue";
import ButtonDeleteOptionalStatement from "./button_delete_optional_statement.vue";

import { useStore, Store } from 'vuex';
import { key } from '@/vuex';
import type { State } from '@/vuex';
const store:Store<State> = useStore(key);

interface Props {
  requestPath:string;
}
const props = withDefaults(defineProps<Props>(), {});
const emit = defineEmits(['click-row', 'delete']);

// == computed ==
const { optionalStatements } = cmpOptionalStatements(store);
// == methods ==
const { onClickRow } = mtdOnClickRow(emit);
const { onDelete } = mtdOnDelete(emit);
</script>

<template lang="pug">
.uk-overflow-auto.juno-optional-statements
  table#table_optional_statements.uk-table.uk-table-striped.uk-table-hover.uk-table-middle
    thead
      tr
        th.uk-text-center ID
        th 計上日
        th.uk-width-medium 項目名
        th.uk-text-center.uk-text-nowrap 金額（税込）
        th
    tbody
      tr(v-for="statement in optionalStatements" :key="statement.id" @click="onClickRow(statement)")
        TableData(:value="statement.id" cell-class="juno-optional-statements-cell uk-flex uk-flex-center uk-flex-middle")
        TableData(:value="statement.formattedRecordedDate")
        TableData(:value="statement.name")
        TableData(:value="statement.delimitedAmount" cell-class="uk-text-right uk-margin-small-right")
        TableData(cell-class="uk-flex uk-flex-right uk-margin-small-right" v-slot)
          ButtonDeleteOptionalStatement.uk-margin-small-right(:requestPath="requestPath" :optionalStatementId="statement.id" @delete="onDelete(statement)") 削除
</template>
