<script setup lang="ts">
import refs from "@/composables/common/panels/shared_files/form_category/refs";

import { useStore, Store } from 'vuex';
import { key } from '@/vuex';
import type { State } from '@/vuex';
const store:Store<State> = useStore(key);

interface Props {
	category:number;
	railsDirectUploadsUrl:string;
	fileBlobId:string;
	fileName:string;
	url:string;
	categoryOptions:Array<any>;
}
const props = defineProps<Props>();

// == ref ==
const { category, url } = refs(props);
</script>

<template lang="pug">
.uk-width-1-1.uk-margin-small-bottom
	.form-group.uk-flex
		.uk-form-horizontal.juno-form-height
			.uk-form-label.uk-flex
				label.uk-flex カテゴリー
				span.uk-label-danger.juno-required-label 必須
			.uk-form-controls.juno-radio-buttons.uk-flex
				label.juno-radio-button
					input(type="radio" :value="1" key="category" v-model="category" name="shared_file[category]" id="shared_file_category")
					| ファイル
				label.juno-radio-button
					input(type="radio" :value="2" key="category" v-model="category" name="shared_file[category]" id="shared_file_category")
					| URL
.uk-grid(class="uk-child-width-1-1@m uk-child-width-1-2@l")
	.uk-margin-small-bottom(v-if="category === 1")
		.form-group.uk-flex
			.uk-form-horizontal
				.uk-form-label.uk-flex
					label.uk-flex ファイル選択
					span.uk-label-danger.juno-required-label 必須
				.uk-form-controls
					div(uk-form-custom="target: true")
						input(type="file" name="shared_file[file]" id="shared_file_file" :data-direct-upload-url="railsDirectUploadsUrl")
						input.uk-input.uk-form-width-medium(type="text", :placeholder="fileName || 'ファイルを選択'")
						input(v-if="fileBlobId" name="shared_file[file_blob_id]" id="shared_file_file_blob_id" :value="fileBlobId" class='uk-hidden')
	.uk-margin-small-bottom(v-if="category === 2")
		.form-group.uk-flex
			.uk-form-horizontal.uk-margin-small-right
				.uk-form-label.uk-flex
					label.uk-flex URL
					span.uk-label-danger.juno-required-label 必須
				.uk-form-controls
					input.uk-input.uk-form-width-large(v-model="url" name="shared_file[url]" id="shared_file_url")
</template>
