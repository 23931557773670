export default (modelName: string, randomPassword: (len: number) => string) => {
  const setPassword = () => {
    // パスワードの長さ
    const passLen = 8;
    // 生成したパスワード
    const newPassword = randomPassword(passLen);

    // 入力フォームにパスワードを設定
    _el_password(modelName).value = newPassword;
    _el_confirmId(modelName).value = newPassword;
  }

  return { setPassword };
}

// パスワード入力フォームのID属性値
function _el_password(modelName: string) {
  const el:any = document.getElementById(`${modelName}_password`);
  return el;
}

// パスワード（確認）入力フォームのID属性値
function _el_confirmId(modelName: string) {
  const el:any = document.getElementById(`${modelName}_password_confirmation`);
  return el;
}
