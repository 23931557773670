<script setup lang="ts">
import mtdOnClick from "@/composables/common/panels/donations/edit/button_update_inquiry/methods/mtdOnClick";

import { useStore, Store } from 'vuex';
import { key } from '@/vuex';
import type { State } from '@/vuex';
const store:Store<State> = useStore(key);

interface Props {
  requestPath:string,
  inquiryId:number,
  donationId:number,
  receivedAt?:Date|null,
  body:string
}
const props = withDefaults(defineProps<Props>(), {
  receivedAt: null,
  body: ''
});

const { onClick } = mtdOnClick(store);
</script>

<template lang="pug">
button#button_update_inquiry.uk-button.uk-button-default(
  type="button"
  :disabled="!body"
  @click="onClick(requestPath, inquiryId, donationId, receivedAt, body)"
) 更新
</template>
