import { getInquiries } from '@/vuex/store/inquiries/mutations/mutGetInquiries';
import { push } from '@/vuex/store/inquiries/mutations/mutPush';
import { remove } from '@/vuex/store/inquiries/mutations/mutRemove';
import { splice } from '@/vuex/store/inquiries/mutations/mutSplice';
import { Inquiry } from "@/models";

const state = () => {
  return {
    inquiries: [], // 問い合わせ
  }
};

export interface inquiriesState {
  inquiries: Inquiry[],
}

export const inquiries = {
  namespaced: true,
  state,
  mutations: {
    'getInquiries': getInquiries,
    'push': push,
    'remove': remove,
    'splice': splice
  }
};
