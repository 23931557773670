import BinderBase from '@/binders/binder_base';
import ButtonEnableRedownload from '@/components/common/panels/payment_statement_histroies/index/button_enable_redownload';

class ButtonEnableRedownloadBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '.button-enable-redownload',
        app: ButtonEnableRedownload
      }
    ];
  }
}

export default ButtonEnableRedownloadBinder;
