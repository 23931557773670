import BinderBase from '../../binder_base';
import ButtonClearSearch from '../../../components/common/buttons/button_clear_search';

class ButtonClearSearchBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#button_clear_search',
        app: ButtonClearSearch
      }
    ];
  }
}

export default ButtonClearSearchBinder;
