import axios from "@/libs/Axios";
import { AxiosResponse } from 'axios';
import { ukNotify } from "@/composables/common/common/methods/mtdUIKit";
import { Store } from 'vuex';
import { State } from '@/vuex';
import { DownloadFile } from "@/models";

export default (store:Store<State>, requestPath:string, downloadFileId:number) => {
  const onClick = async () => {
    // 再ダウンロード可能にする
    axios
      .put(requestPath, updateParams(downloadFileId))
      .then((response:AxiosResponse) => {
        const resData = response && response.data ? response.data : {};
        const downloadFiles = resData.map((data:any) => new DownloadFile(data));
        // storeに追加してヘッダのアイコン表示を変える
        store.commit('downloadFiles/replaceAll', downloadFiles)
        notifySuccess();
        return true;
      })
    .catch(() => {
      notifyError();
      return null;
    });
  }

  function updateParams(downloadFileId:number) {
    return {
      id: downloadFileId,
    }
  }

  function notifySuccess() {
    ukNotify('再ダウンロードできるようになりました。');
  }

  function notifyError() {
    ukNotify('再ダウンロードできませんでした。');
  }

  return { onClick }
}
