import { ref } from "vue";

export default (storeIdValue:number|null) => {
  // 選択中store_id
  const selectedId = ref(storeIdValue);

  return {
    selectedId
  };
};
