import BinderBase from '@/binders/binder_base';
import FormInvoiceInfo from '@/components/common/panels/store_settings/edit/form_invoice_info';

class FormInvoiceInfoBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#form_invoice_info',
        app: FormInvoiceInfo
      }
    ];
  }
}

export default FormInvoiceInfoBinder;
