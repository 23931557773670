<script setup lang="ts">
import { ref, computed, onMounted } from "vue";

interface Props {
  mailToDonations: Array<any>;
  selectedDonationIds: Array<number>;
  mailToNote: String;
}
const props = defineProps<Props>();
const mailToDonations = ref(props.mailToDonations)
const selectedDonationIds = ref(mailToDonations.value.map((donation: any) => donation.id))
const isSelectSendTarget = ref(false)

const displayLabelText = (donation: any) => {
  return `${donation.last_name} ${donation.first_name}(${donation.email})`
}

// メールアドレスの出現回数をカウントする関数
const countEmailOccurrences = (donations: Array<any>) => {
  return donations.reduce((counts, donation) => {
    counts[donation.email] = (counts[donation.email] || 0) + 1;
    return counts;
  }, {} as Record<string, number>);
};

// 重複しているメールアドレスのセットを取得
const duplicateEmails = computed(() => {
  const emailCounts = countEmailOccurrences(mailToDonations.value);
  return new Set(Object.keys(emailCounts).filter(email => emailCounts[email] > 1));
});

// 各 donation のメールアドレスが重複しているかを判定
const isDuplicateEmail = (email: string) => {
  return duplicateEmails.value.has(email);
}

// メールアドレスに重複があるかどうか
const hasDuplicateEmails = computed(() => {
  const emailCounts = countEmailOccurrences(mailToDonations.value);
  return Object.values(emailCounts).some(count => count > 1);
});

const toggleSelection = (event: Event, donationId: any) => {
  const target = event.target as HTMLInputElement;
  if (selectedDonationIds.value.includes(donationId)) {
    if (selectedDonationIds.value.length > 1) {
      selectedDonationIds.value = selectedDonationIds.value.filter(id => id !== donationId);
    } else {
      target.checked = true;
    }
  } else {
    selectedDonationIds.value.push(donationId);
  }
};

const removeDuplicateSelections = () => {
  const uniqueEmails = new Map<number, any>();
  
  // 各メールアドレスに対して、idが一番大きいものだけを選択
  mailToDonations.value.forEach((donation: any) => {
    if (!uniqueEmails.has(donation.email) || donation.id > uniqueEmails.get(donation.email).id) {
      uniqueEmails.set(donation.email, donation);
    }
  });
  
  // 選択されたdonationのidのリストを更新
  selectedDonationIds.value = Array.from(uniqueEmails.values()).map((donation: any) => donation.id);
};

onMounted(() => {
  if (!props.selectedDonationIds.length) return

  // MEMO: propsで渡された際に中身がstringになってしまっているので変換する
  selectedDonationIds.value = props.selectedDonationIds.map((str: string) => parseInt(str, 10));
  isSelectSendTarget.value = true
});
</script>

<template lang="pug">
.form-group.uk-flex
  .uk-form-horizontal
    .uk-form-label 送信先アドレス
    .uk-form-controls.uk-text-small.juno-margin-xxsmall-top(v-if="isSelectSendTarget")
      .uk-form-horizontal.juno-form-height
        span.uk-margin-small-right(uk-icon="info")
        span.uk-text-middle 1件未満に設定することはできません
      .uk-flex(v-for="donation in mailToDonations" :key="donation.id")
        input(type='checkbox', name="mail_schedule[donation_ids][]", :id="`mail_schedule_donation_ids_${donation.id}`", :value="donation.id" :checked="selectedDonationIds.includes(donation.id)" @change="toggleSelection($event, donation.id)")
        label {{ displayLabelText(donation) }}
        span(uk-tooltip="送信先アドレスの中に同一のアドレスがあります", v-if="isDuplicateEmail(donation.email)") 【重複】
      a.uk-display-block(@click="isSelectSendTarget = false") すべて送信対象とする
      .uk-flex.uk-flex-middle(v-if="hasDuplicateEmails")
        a.uk-display-block.uk-margin-small-right(@click="removeDuplicateSelections") 重複するアドレスを除外する
        span(uk-icon="info")
        span.uk-text-middle 一番新しい寄附情報のチェックが残ります
    .uk-form-controls.uk-text-small.juno-margin-xxsmall-top(v-else)
      p.uk-margin-remove-bottom(style="white-space:pre-wrap; word-wrap:break-word;") {{ mailToNote }}
      a(v-if="mailToDonations.length > 1" @click="isSelectSendTarget = true") 送信対象を選択する
</template>
