import BinderBase from '@/binders/binder_base';
import ModalAddOptionalStatements from '@/components/common/panels/payment_statements/donations/index/modal_add_optional_statements';

class ModalAddOptionalStatementsBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#modal_add_optional_statements',
        app: ModalAddOptionalStatements
      }
    ];
  }
}

export default ModalAddOptionalStatementsBinder;
