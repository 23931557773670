import { ref } from "vue";

export default (props:any) => {
  const approvalDate = ref(props.approvalDate);
  const rejectionDate = ref(props.rejectionDate);
  
  return {
    approvalDate,
    rejectionDate
  };
};
