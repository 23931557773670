import { ref } from "vue";

export default (props:any) => {
  const allShopsFlag = ref(props.allShopsFlag);
  const storesByPrefecture = ref(props.storesByPrefecture);
  const storeIds = ref(props.storeIds);

  return {
    allShopsFlag,
    storesByPrefecture,
    storeIds,
  };
};