import BinderBase from '@/binders/binder_base';
import ButtonReintegrateWithRedhorse from '@/components/common/panels/donations/edit/button_reintegrate_with_redhorse';

class ButtonReintegrateWithRedhorseBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#button_reintegrate_with_redhorse',
        app: ButtonReintegrateWithRedhorse
      }
    ];
  }
}

export default ButtonReintegrateWithRedhorseBinder;
