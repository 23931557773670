import axios from "@/libs/Axios";
import { AxiosResponse } from 'axios';
import { DownloadFile } from "@/models";
import { Store } from 'vuex';
import { State } from '@/vuex';
import { ukNotify, ukConfirm } from "@/composables/common/common/methods/mtdUIKit";

export default (store:Store<State>, fileLabel:string) => {
  async function createFile (requestPath:string, dataDiv:string, extension:string, target_ids: Array<number>): Promise<any> {
    // 確認ポップアップ
    ukConfirm(`${fileLabel}をダウンロードしますか？`)
      .then(() => {
        // ファイル生成リクエスト送信
        const params = createParams(dataDiv, extension, target_ids);
        create(requestPath, params, store);
      }).catch(() => {
      // キャンセル時は何もしない
    })
  }

  async function create (requestPath:string, params: any, store:Store<State>) {
    try {
      return await axios.post(requestPath, params).then((response:AxiosResponse) => {
        const resData = response && response.data ? response.data : {};
        const downloadFile = new DownloadFile(resData);

        // storeに追加してヘッダのアイコン表示を変える
        store.commit('downloadFiles/unshift', downloadFile);

        notifySuccess();

        return downloadFile;
      });
    } catch (error) {
      notifyError();
    }
  }

  function notifySuccess() {
    const message = `${fileLabel}の作成を開始しました。<br />完了しましたら画面右上のファイルダウンロードメニューからダウンロードできます。`;
    ukNotify(message);
  }

  return { createFile }
}

function createParams (dataDiv:string, extension:string, target_ids: Array<number>) {
  return {
    'download_file': {
      'data_div': dataDiv,
      'extension': extension,
      'target_ids': target_ids
    }
  };
}

function notifyError() {
  ukNotify('ファイルを作成できませんでした。');
}
