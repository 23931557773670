<script setup lang="ts">
import cmpStoreState from "@/composables/common/common/computed/cmpStoreState";
import cmpIsGenerating from "@/composables/common/buttons/display_download_files/computed/cmpIsGenerating";
import mtdGetDownloadFiles from "@/composables/common/buttons/display_download_files/methods/mtdGetDownloadFiles";
import DownloadFileRow from '@/components/common/buttons/display_download_files/download_file_row.vue';

import { useStore, Store } from 'vuex';
import { key } from '@/vuex';
import type { State } from '@/vuex';
const store:Store<State> = useStore(key);
import { onUnmounted } from 'vue';

interface Props {
  requestPath: string; // リクエストパス
}
const props = defineProps<Props>();

// == computed ==
// ダウンロード対象ファイル情報
const { downloadFiles, isGettingDlFiles } = cmpStoreState(store);

// １つ以上のファイルが生成中か
const { isGeneratingAny } = cmpIsGenerating(store);

// == methods ==
const { getDownloadFiles } = mtdGetDownloadFiles(store);

// onCreated
// ファイル生成中は定期的にファイル情報を再取得
let getCount = 0;
const intervalID = setInterval(() => {
  if (getCount === 0 || isGeneratingAny.value) { // 最低1回は取得する
    getDownloadFiles(props.requestPath);
    getCount++;
  }
}, 5000);

onUnmounted (() => {
  clearInterval(intervalID);
})
</script>

<template lang="pug">
.uk-inline
  a.uk-button.uk-button-link#download_files_display
    div(v-if="isGeneratingAny || isGettingDlFiles" uk-spinner="ratio: 0.5")
    span(v-else uk-icon="cloud-download")
    span.juno-icon-text-gap ダウンロード
  .uk-width-large.juno-download-files(uk-dropdown="pos: bottom-right")
    div(v-if="isGettingDlFiles" key="getting_list")
      .uk-text-center
        | ダウンロード済データの一覧を取得中です
    div(v-else-if="downloadFiles.length === 0" key="no_download_file")
      .uk-text-center
        | ダウンロード済のデータがここに表示されます
    div(v-else key="download_files_exist")
      div(v-for="(downloadFile, index) in downloadFiles" :key="downloadFiles.id")
        DownloadFileRow(:download-file="downloadFile")
        hr(v-if="index < downloadFiles.length - 1")
</template>
