<script setup lang="ts">
import refs from "@/composables/common/panels/donations/edit/modal_inquiry/refs";
import cmpButtonCloseIdKey from "@/composables/common/panels/donations/edit/modal_inquiry/computed/cmpButtonCloseIdKey";
import cmpOperationText from "@/composables/common/common/computed/cmpOperationText";
import mtdClearModalForms from "@/composables/common/panels/donations/edit/modal_inquiry/methods/mtdClearModalForms";
import FormDatetime from "@/components/common/input_forms/form_datetime.vue";
import FormTextarea from "@/components/common/input_forms/form_textarea.vue";
import ButtonCloseModal from "@/components/common/buttons/button_close_modal.vue";
import ButtonCreateInquiry from "@/components/common/panels/donations/edit/button_create_inquiry.vue";
import ButtonUpdateInquiry from "@/components/common/panels/donations/edit/button_update_inquiry.vue";
import ButtonDeleteInquiry from "@/components/common/panels/donations/edit/button_delete_inquiry.vue";

import { watch } from 'vue';

interface Props {
  idKey:string
  requestPath:string,
  donationId:number,
  inquiryId?:number|null,
  receivedAtValue?:string,
  bodyValue?:string
}
const props = withDefaults(defineProps<Props>(), {
  inquiryId: null
});
// == ref ==
const { receivedAt, body } = refs(props.receivedAtValue, props.bodyValue);
// == computed ==
const { operationText } = cmpOperationText(() => props.inquiryId);
const { buttonCloseIdKey } = cmpButtonCloseIdKey(() => props.inquiryId);
// == methods ==
const { clearModalForms } = mtdClearModalForms(receivedAt, body);

// == watch
// propsの問い合わせ情報が変わったらrefsに反映
watch(() => props.receivedAtValue, (newVal) => {
  receivedAt.value = newVal ? new Date(Date.parse(newVal)) : null;
});
watch(() => props.bodyValue, (newVal) => {
  body.value = newVal ? newVal : '';
});
</script>

<template lang="pug">
div(:id="idKey" uk-modal)
  .uk-modal-dialog.uk-modal-body.juno-modal
    .juno-modal-title
      h4.uk-margin-medium-bottom {{`問い合わせの${operationText}`}}
    .juno-modal-body
      FormDatetime.uk-margin-small-bottom(
        v-model="receivedAt"
        labelText="問い合わせ日時"
        :required="false"
        modelName="inquiry"
        columnName="received_at"
        :modalForm="true"
      )
      FormTextarea(
        v-model="body"
        labelText="問い合わせ内容"
        :required="false"
        modelName="inquiry"
        columnName="body"
        :rows="10"
        :cols="100"
      )
    .juno-modal-footer.uk-flex.uk-flex-right
      .uk-margin-small-right
        .uk-flex(v-if="inquiryId")
          ButtonDeleteInquiry.uk-margin-small-right(
            :request-path="requestPath"
            :inquiry-id="inquiryId"
          )
          ButtonUpdateInquiry(
            :request-path="requestPath"
            :inquiry-id="inquiryId"
            :donation-id="donationId"
            :received-at="receivedAt"
            :body="body"
          )
        ButtonCreateInquiry(
          v-else
          :request-path="requestPath"
          :donation-id="donationId"
          v-model:received-at="receivedAt"
          v-model:body="body"
        )
      ButtonCloseModal(:idKey="buttonCloseIdKey" label-text="キャンセル" @click="clearModalForms")
</template>
