import axios from "@/libs/Axios";
import { AxiosResponse } from 'axios';
import UIkit from 'uikit';
import { ukNotify } from "@/composables/common/common/methods/mtdUIKit";
import { Store } from 'vuex';
import { State } from '@/vuex';
import { Inquiry } from "@/models";
import mtdFormatDatetime from "@/composables/common/common/methods/mtdFormatDatetime";

export default (store:Store<State>) => {
  const onClick = async (requestPath:string, inquiryId:number, donationId:number, receivedAt:Date|null, body:string) => {
    // 問い合わせデータをupdate
    const updatePath = `${requestPath}/${inquiryId}`
    axios
      .put(updatePath, updateParams(donationId, receivedAt, body))
      .then((response:AxiosResponse) => {
        const resData = response && response.data ? response.data : {};
        const inquiry = new Inquiry(resData);
        // storeにinquiryを置き換える
        store.commit('inquiries/splice', inquiry)

        closeModal();

        return true;
      })
      .catch(() => {
        notifyError();
        return null;
      });
  }

  function updateParams (donationId:number, receivedAt:Date|null, body:string) {
    const { formatDatetime } = mtdFormatDatetime();
    return {
      'inquiry': {
        'donation_id': donationId,
        'received_at': formatDatetime(receivedAt),
        'body': body
      }
    };
  }

  function notifyError() {
    ukNotify('問い合わせを更新できませんでした。');
  }

  function closeModal() {
    const element = document.getElementById('update_inquiry_modal');
    UIkit.modal(element)?.hide();
    ukNotify('問い合わせを更新しました。');
  }

  return { onClick }
}
