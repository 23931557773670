import axios from "@/libs/Axios";
import { AxiosResponse } from 'axios';
import UIkit from 'uikit';
import { ukNotify } from "@/composables/common/common/methods/mtdUIKit";
import { Store } from 'vuex';
import { State } from '@/vuex';

export default (store:Store<State>) => {
  const onClick = async (requestPath:string, inquiryId:number) => {
    // 問い合わせデータをdelete
    const deletePath = `${requestPath}/${inquiryId}`;
    axios
      .delete(deletePath)
      .then((_response:AxiosResponse) => {
        // storeからinquiryを削除
        store.commit('inquiries/remove', inquiryId)

        closeModal();

        return true;
      })
      .catch(() => {
        notifyError();
        return null;
      });
  }

  function notifyError() {
    ukNotify('問い合わせを削除できませんでした。');
  }

  function closeModal() {
    const element = document.getElementById('update_inquiry_modal');
    UIkit.modal(element)?.hide();
    ukNotify('問い合わせを削除しました。');
  }

  return { onClick }
}
