import BinderBase from '@/binders/binder_base';
import DisplayHelp from '@/components/common/buttons/display_help';

class DisplayHelpBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#display_help',
        app: DisplayHelp
      }
    ];
  }
}

export default DisplayHelpBinder;
