import { ref, Ref } from "vue";
import { Inquiry } from "@/models";

export default () => {
  // 問い合わせ一覧で選択した問い合わせ内容
  const selectedInquiry:Ref<Inquiry|null> = ref(null);

  return {
    selectedInquiry
  };
};
