import { Ref } from 'vue';
import { computed } from "vue";
import { PaymentStatementSetting, TempPaymentSummary } from '@/models';

export default (paymentStatementSetting:Ref<PaymentStatementSetting>, tempPaymentSummaries:Ref<TempPaymentSummary[]>, issueDate:Ref<Date>) => {
  return {
    disabled: computed(() => {
      return !paymentStatementSetting.value || !tempPaymentSummaries.value || tempPaymentSummaries.value.length === 0 || !issueDate.value;
    })
  }
}
