export default () => {
  const randomPassword = (len: number) => {
    //ランダムな文字列の生成
    let result = "";
    for(var i=0;i<len;i++){
      result += _str().charAt(Math.floor(Math.random() * _str().length));
    }

    return result;
  }

  return { randomPassword }
}

//使用文字の定義
function _str () {
  return "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
}
