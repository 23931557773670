<script setup lang="ts">
import mtdFormatDate from "@/composables/common/common/methods/mtdFormatDate";
import Datepicker from '@vuepic/vue-datepicker';

interface Props {
  labelText: string; // 入力フォームのラベルテキスト
  modelName: string; // 自model名
  columnName: string; // カラム名
  modelValue?: Date|null; // 日付(Date型）
  modalForm?: boolean; // モーダルのフォームかどうか
  disabled?: boolean; // disabledかどうか
  requiredLabel?: boolean; // 必須ラベルの表示
  clearable?: boolean; // クリアボタンの表示
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  modalForm: false,
  disabled: false,
  requiredLabel: false,
  clearable: true
});
const emit = defineEmits(['update:modelValue']);
const onUpdate = () => emit('update:modelValue', props.modelValue);

// == methods ==
const { formatDate } = mtdFormatDate();
</script>

<template lang="pug">
.form-group.uk-flex
  .uk-form-horizontal.uk-margin-small-right
    label.uk-form-label.uk-flex
      |  {{ labelText }}
      span.uk-label-danger.juno-required-label(v-if="requiredLabel") 必須
    .uk-form-controls
      div
        Datepicker.datepicker(
          v-model="modelValue"
          :id="`${modelName}_${columnName}`"
          :name="`${modelName}[${columnName}]`"
          :format="formatDate"
          :previewFormat="formatDate"
          :disabled="disabled"
          :clearable="clearable"
          @update:modelValue="onUpdate"
          :enableTimePicker="false"
          :autoApply="true"
          locale="ja"
          weekStart="0"
          inputClassName="uk-text-muted juno-form-width-date juno-form-height"
        )
</template>
