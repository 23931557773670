import { computed, Ref } from "vue"
import MailCustomTemplate from "@/models/MailCustomTemplate";

export default (mailCustomTemplates: Array<MailCustomTemplate>, selectedId: Ref<string>) => {
  return {
    selectedTemplate: computed(() => {
      const orgTemplate:MailCustomTemplate|undefined = mailCustomTemplates.find(template => String(template.id) === String(selectedId.value))
      return orgTemplate ? new MailCustomTemplate(orgTemplate) : new MailCustomTemplate({});
    })
  }
}
