import BinderBase from '../../binder_base';
import FormSelectPolymorphicL from '../../../components/common/input_forms/form_select_polymorphic_l';

class FormSelectPolymorphicLBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#form_select_polymorphic_l',
        app: FormSelectPolymorphicL
      }
    ];
  }
}

export default FormSelectPolymorphicLBinder;
