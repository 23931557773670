import { reactive, ref, Ref } from "vue";

export default (props:any) => {  
  let existDataLastOrder:Ref<number> = ref(0);
  const countClick:Ref<number> = ref(0);
  
  const formDataOrders:Array<number> = reactive([]);
  const formDatas:Array<any> = reactive(props.customerSurveyChoices);
    
  return {
    existDataLastOrder,
    countClick,
    formDataOrders,
    formDatas
  };
};