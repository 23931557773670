<script setup lang="ts">
import ModalRejection from "@/components/common/panels/items/common/modal_rejection.vue";

interface Props {
  disabled?: boolean,
  buttonText:string,
  itemId:number,
  itemRejectionReason:string,
  requestPath:string
}
const props = withDefaults(defineProps<Props>(), {
  itemRejectionReason: null
});
const modalIdKey = 'rejection_modal';
</script>

<template lang="pug">
div
  button#item_rejection.uk-button.uk-button-default(:uk-toggle="`target: #${modalIdKey}`" type="button" @click="clearForms" :disabled="disabled")
    span.uk-text-middle 差し戻し

  ModalRejection(
    :id-key="modalIdKey"
    :request-path="requestPath"
    :item-id="itemId"
    :item-rejection-reason="itemRejectionReason"
  )
</template>
