import axios from "@/libs/Axios";
import { AxiosResponse } from 'axios';
import { ukNotify} from "@/composables/common/common/methods/mtdUIKit";
import {DateTime} from "luxon";
import {DownloadFile} from "@/models";
import {Store} from "vuex";
import {State} from "@/vuex";

interface Emits{
  (e: 'create', downloadFileId:number): void
}
export default (store:Store<State>, requestPath:string, emit:Emits) => {
  const onClick = async (issueDate:Date) => {
    // 支払通知書（発行履歴）をcreate
    const params = createParams(issueDate);
    axios
      .post(requestPath, params)
      .then((response:AxiosResponse) => {
        const resData = response && response.data ? response.data : {};
        const downloadFile = new DownloadFile(resData.download_file);

        // storeに追加してヘッダのアイコン表示を変える
        store.commit('downloadFiles/unshift', downloadFile);

        // 親コンポーネントに作成したことを通知
        emit('create', downloadFile.id);
        return true;
      })
      .catch(() => {
        notifyError();
        return null;
      })
  }

  function createParams(issueDate:Date) {
    return {
      payment_statement_history: {
        issue_date: DateTime.fromJSDate(issueDate).toISODate(),
      }
    }
  }

  function notifyError() {
    ukNotify('支払通知書を作成できませんでした。');
  }

  return { onClick }
}
