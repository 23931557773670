import BinderBase from '@/binders/binder_base';
import FormDatetime from '@/components/common/input_forms/form_datetime';

class FormDatetimeBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#form_datetime_donated_at',
        app: FormDatetime
      },
      {
        el: '#form_datetime_paid_at',
        app: FormDatetime
      },
      {
        el: '#form_datetime_personal_info_input_at',
        app: FormDatetime
      },
      {
        el: '#form_datetime_canceled_at',
        app: FormDatetime
      },
      {
        el: '#form_datetime_posted_at',
        app: FormDatetime
      },
      {
        el: '#form_datetime_payment_statement_issued_at',
        app: FormDatetime
      },
      {
        el: '#form_datetime_distribution_started_at',
        app: FormDatetime
      },
      {
        el: '#form_datetime_distribution_ended_at',
        app: FormDatetime
      },
      {
        el: '#form_datetime_expired_at',
        app: FormDatetime
      },
      {
        el: '#form_datetime_display_start_at',
        app: FormDatetime
      },
      {
        el: '#form_datetime_display_end_at',
        app: FormDatetime
      },
      {
        el: '#form_datetime_published_at',
        app: FormDatetime
      }
    ];
  }
}

export default FormDatetimeBinder;
