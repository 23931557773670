import BinderBase from '@/binders/binder_base';
import FormEditSendTarget from '@/components/common/panels/mail_schedules/input_mail/form_edit_send_target';

class FormEditSendTargetBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#form_edit_send_target',
        app: FormEditSendTarget
      }
    ];
  }
}

export default FormEditSendTargetBinder;
