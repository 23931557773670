<script setup lang="ts">
import refs from "@/composables/common/panels/store_settings/form_restricted_donation_use_info/refs";

interface Props {
  restrictedDonationUse: boolean;
  messageRestrictedDonationUse: string;
  donationUses: Array<any>;
  donationUseIds: Array<Number>;
}
const props = defineProps<Props>();

// == ref ==
const { restrictedDonationUse, messageRestrictedDonationUse, donationUses, donationUseIds } = refs(props);

</script>

<template lang="pug">
.uk-width-1-1.uk-margin-small-bottom
  .form-group.uk-flex
    .uk-form-horizontal.juno-form-height
      .uk-form-label.uk-flex
        label.uk-flex 寄附の使途を限定する
      .uk-form-controls.juno-radio-buttons.uk-flex
        label.juno-radio-button
          input(type="radio" :value="true" key="restrictedDonationUse" v-model="restrictedDonationUse" name="store[store_setting_attributes][restricted_donation_use]" id="store_setting_attributes_restricted_donation_use_true")
          | する
        label.juno-radio-button
          input(type="radio" :value="false" key="restrictedDonationUse" v-model="restrictedDonationUse" name="store[store_setting_attributes][restricted_donation_use]" id="store_setting_attributes_restricted_donation_use_false")
          | しない
.uk-margin-small-bottom
  .form-group(v-if="restrictedDonationUse")
    .uk-form-label.uk-flex
      label.uk-flex 寄附使途の選択肢
      span.uk-label-danger.juno-required-label 必須
    .uk-padding-small
      .uk-margin-small-bottom.uk-text-small 寄附者に選択していただきたい使途にチェックをつけてください（複数選択可）
      div(v-for="donationUse in donationUses" :key="donationUse[0]")
        input.uk-checkbox(type='checkbox', name="store[store_setting_attributes][donation_use_ids][]", :id="`store_store_setting_attributes_donation_use_ids_${donationUse[0]}`", :value="donationUse[0]" :checked="donationUseIds.includes(donationUse[0])")
        label.uk-form-label {{ donationUse[1]}}
.uk-margin-small-bottom
  .form-group(v-if="restrictedDonationUse")
    .uk-margin-small-bottom
      label.uk-form-label 決済端末文言（寄附の使途限定の同意）
      span.uk-label-danger.juno-required-label 必須
    .uk-text-small.uk-text-muted ※改行を含め80文字を超えた場合、それ以降は表示されません。
    textarea.uk-input.uk-form-width-auto.juno-text-area-l-vertical(
      v-model="messageRestrictedDonationUse"
      id="store[store_setting_attributes][message_restricted_donation_use]"
      name="store[store_setting_attributes][message_restricted_donation_use]"
    )
</template>
