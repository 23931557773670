<script setup lang="ts">
import cmpRequiredClass from "@/composables/common/input_forms/common/cmpRequiredClass";
import mtdFormatDate from "@/composables/common/common/methods/mtdFormatDate";
import Datepicker from '@vuepic/vue-datepicker';

interface Props {
  labelText: string; // 入力フォームのラベルテキスト
  columnName: string; // カラム名
  startDateValue?: Date|null; // 開始日時(Date型）
  endDateValue?: Date|null; // 終了日時(Date型）
  disabled: boolean; // disabledかどうか
  requiredLabel?: boolean; // 必須ラベルの表示
  clearable?: boolean; // クリアボタンの表示
}
const props = withDefaults(defineProps<Props>(), {
  startDateValue: null,
  endDateValue: null,
  disabled: false,
  requiredLabel: false,
  clearable: true
});

// == methods ==
const { formatDate } = mtdFormatDate();
</script>

<template lang="pug">
.form-group.uk-flex
  .uk-form-horizontal.uk-margin-small-right
    .uk-form-label.uk-flex
      label
        |  {{ labelText }}
      span.uk-label-danger.juno-required-label(v-if="requiredLabel") 必須
    .uk-form-controls.uk-flex.uk-flex-nowrap
      Datepicker.datepicker.uk-margin-small-right(
        v-model="startDateValue"
        :id="`q_${columnName}_gteq`"
        :name="`q[${columnName}_gteq]`"
        inputClassName="juno-search-form juno-search-form-clear uk-text-muted juno-form-width-date juno-form-height"
        :format="formatDate"
        :previewFormat="formatDate"
        :disabled="disabled"
        :clearable="clearable"
        :enableTimePicker="false"
        :autoApply="true"
        locale="ja"
        weekStart="0"
      )
      .uk-flex.uk-flex-middle
        | 〜
      Datepicker.datepicker.uk-margin-small-left(
        v-model="endDateValue"
        :id="`q_${columnName}_lteq_end_of_day`"
        :name="`q[${columnName}_lteq_end_of_day]`"
        inputClassName="juno-search-form juno-search-form-clear uk-text-muted juno-form-width-date juno-form-height"
        :format="formatDate"
        :previewFormat="formatDate"
        :disabled="disabled"
        :clearable="clearable"
        :enableTimePicker="false"
        :autoApply="true"
        locale="ja"
        weekStart="0"
      )
</template>
