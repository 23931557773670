<script setup lang="ts">
import { ref } from "vue";
import cmpSelectedTemplate
  from "@/composables/common/other_forms/form_select_custom_template/computed/cmpSelectedTemplate";
import mtdCopyTemplate from "@/composables/common/other_forms/form_select_custom_template/methods/mtdCopyTemplate";
import MailCustomTemplate from "@/models/MailCustomTemplate";

interface Props {
  mailCustomTemplates: Array<MailCustomTemplate>; // optionタグに表示する内容
}
const props = defineProps<Props>();

// == ref ==
const selectedId = ref('');
// == computed ==
const { selectedTemplate } = cmpSelectedTemplate(props.mailCustomTemplates, selectedId);
// == methods ==
const { copyTemplate } = mtdCopyTemplate();
</script>

<template lang="pug">
.input.select
  select.is-valid.select.uk-select.uk-form-width-large(
    v-model="selectedId"
    id="select_mail_custom_template"
    :value="selectedId"
    @change="copyTemplate(selectedTemplate)"
  )
    option(v-for="template in mailCustomTemplates" :value="template.id") {{ template.subject }}
</template>
