import { Ref } from 'vue';
import * as UIkit from "uikit";

export default (targetFileId:Ref<number>) => {
  const onCreate =(downloadFileId:number) => {
    // 一旦モーダルを閉じる（uk-modalのプロパティを書き換えると、モーダルが閉じてしまうため、一旦モーダルを閉じる）
    const element = document.getElementById('confirm_issue_payment_statement');
    UIkit.modal(element).hide();

    targetFileId.value = downloadFileId;

    // モーダルを開く（bg-closeの値が書き換わるのを）
    setTimeout(() => UIkit.modal(element).show(), 100);
  }
  return { onCreate }
}
