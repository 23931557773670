<script setup lang="ts">
import mtdOnClick from "@/composables/common/panels/payment_statements/donations/index/button_create_optional_statement/methods/mtdOnClick";
import cmpDisabled from "@/composables/common/panels/payment_statements/donations/index/common/computed/cmpDisabled";

import { toRefs } from 'vue'
import { useStore, Store } from 'vuex';
import { key } from '@/vuex';
import type { State } from '@/vuex';
const store:Store<State> = useStore(key);

interface Props {
  requestPath:string,
  storeIds:string[],
  recordedDate:Date|null,
  name:string,
  amount:string,
  taxRate:string,
}
const props = withDefaults(defineProps<Props>(), {
});
const emit = defineEmits(['update:storeIds', 'update:recordedDate', 'update:name', 'update:amount', 'update:taxRate']);
// == ref ==
const { storeIds, recordedDate, name, amount, taxRate } = toRefs(props);

// == computed ==
const { disabled } = cmpDisabled(storeIds, recordedDate, name, amount, taxRate);

// == methods ==
const { onClick } = mtdOnClick(store);
</script>

<template lang="pug">
button#button_create_optional_statement.uk-button.uk-button-default(
  type="button"
  :disabled="disabled"
  @click="onClick(requestPath, storeIds, recordedDate, name, amount, taxRate, emit)"
) 追加
</template>
