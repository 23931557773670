import { ref } from "vue";

export default (value:string) => {
  // 選択中の拡張子
  const extension = ref(value);

  return {
    extension
  };
};
