import { Ref } from "vue";
import {OptionalStatement} from "@/models";

export default (id: Ref<string|null>, storeIds: Ref<string[]>, recordedDate: Ref<Date|null>, name: Ref<string>, amount: Ref<string>, taxRate: Ref<string>) => {
  const onClickRow = (optionalStatement:OptionalStatement) => {
    id.value = String(optionalStatement.id) || null;
    storeIds.value = optionalStatement.storeIds.map((storeId) => String(storeId));
    recordedDate.value = optionalStatement.recordedDate.toJSDate();
    name.value = optionalStatement.name;
    amount.value = String(optionalStatement.amount);
    taxRate.value = optionalStatement.taxRate ? String(optionalStatement.taxRate) : '';
  }

  return { onClickRow }
}
