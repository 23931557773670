import BinderBase from '@/binders/binder_base';
import ButtonAddInquiry from '@/components/common/panels/donations/edit/button_add_inquiry';

class ButtonAddInquiryBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#button_add_inquiry',
        app: ButtonAddInquiry
      }
    ];
  }
}

export default ButtonAddInquiryBinder;
