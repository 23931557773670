import { Ref } from 'vue';
import { computed } from 'vue';

export default (formDatas:Ref<Array>) => {
  return {
    disabled : computed(() =>{ if(formDatas.length <= 1 ){
      return true 
      } else {
      return false
      };
    })
  }
}
