import { Store } from 'vuex';
import { State } from '@/vuex';

export default (store:Store<State>, requestPath:string) => {
  const getTempPaymentSummaries = () => {
    const params = {
      requestPath
    };
    store.commit('tempPaymentSummaries/getTempPaymentSummaries', params);
  }

  return { getTempPaymentSummaries }
}
