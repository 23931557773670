import { getDownloadFiles } from '@/vuex/store/downloadFiles/mutations/mutGetDownloadFiles';
import { unshift } from "@/vuex/store/downloadFiles/mutations/mutUnshift";
import { replaceAll } from "@/vuex/store/downloadFiles/mutations/mutReplaceAll";
import { DownloadFile } from "@/models";

const state = () => {
  return {
    downloadFiles: [], // ダウンロード対象ファイル情報
    isGettingList: true, // ダウンロード対象ファイル情報取得中か
  }
};

const getters = {
  // １つ以上のファイルが生成中か
  isGeneratingAny: (state:downloadFilesState) => {
    return Boolean(state.downloadFiles.find(downloadFile => downloadFile.isGenerating && !downloadFile.isTimeout));
  }
}

export interface downloadFilesState {
  downloadFiles: DownloadFile[],
  isGettingList: Boolean,
}

export const downloadFiles = {
  namespaced: true,
  state,
  getters,
  mutations: {
    'getDownloadFiles': getDownloadFiles,
    'unshift': unshift,
    'replaceAll': replaceAll,
  }
};
