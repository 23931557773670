<script setup lang="ts">
import mtdOnClick from "@/composables/common/panels/donations/edit/button_create_inquiry/methods/mtdOnClick";

import { useStore, Store } from 'vuex';
import { key } from '@/vuex';
import type { State } from '@/vuex';
const store:Store<State> = useStore(key);

interface Props {
  requestPath:string,
  donationId:number,
  receivedAt?:Date|null,
  body?:string
}
const props = withDefaults(defineProps<Props>(), {
  receivedAt: null,
  body: ''
});
const emit = defineEmits(['update:receivedAt', 'update:body']);

const { onClick } = mtdOnClick(store);
</script>

<template lang="pug">
button#button_create_inquiry.uk-button.uk-button-default(
  type="button"
  :disabled="!body"
  @click="onClick(requestPath, donationId, receivedAt, body, emit)"
) 追加
</template>
