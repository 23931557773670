import BinderBase from '@/binders/binder_base';
import FormSelectPayType from '@/components/common/search_forms/form_select_pay_type';

class FormSelectPayTypeBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#form_select_pay_type',
        app: FormSelectPayType
      }
    ];
  }
}

export default FormSelectPayTypeBinder;
