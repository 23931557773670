<script setup lang="ts">
import refs from "@/composables/common/panels/shared_files/form_public/refs";

import { useStore, Store } from 'vuex';
import { key } from '@/vuex';
import type { State } from '@/vuex';
const store:Store<State> = useStore(key);

interface Props {
	selectedPublic: string;
	cities: Array<any>;
	currentCityIds: Array<Number>;
}
const props = defineProps<Props>();

const { selectPublic, cityIds } = refs(props);
</script>

<template lang="pug">
.uk-width-1-1.uk-margin-small-bottom
  .form-group.uk-flex
    .uk-form-horizontal.juno-form-height
      .uk-form-label.uk-flex
        label.uk-flex 全ての自治体に公開
        span.uk-label-danger.juno-required-label 必須
      .uk-form-controls.juno-radio-buttons.uk-flex
        label.juno-radio-button
          input(type="radio" :value="true" key="public" v-model="selectPublic" name="shared_file[public]" id="shared_file_public")
          | 公開
        label.juno-radio-button
          input(type="radio" :value="false" key="public" v-model="selectPublic" name="shared_file[public]" id="shared_file_public")
          | 制限する
.uk-grid(class="uk-child-width-1-1@m uk-child-width-1-2@l")
  .uk-margin-small-bottom(v-if="selectPublic !== null && selectPublic === false")
    .form-group.uk-flex
    .uk-form-horizontal.uk-margin-small-right
      .uk-form-label.uk-flex
        label.uk-flex 公開対象自治体
        span.uk-label-danger.juno-required-label 必須
    .uk-form-controls
      div(v-for="city in cities" :key="city[0]")
        label.uk-flex.uk-flex-middle
          .juno-checkbox
            input.uk-checkbox(type='checkbox', name="shared_file[city_ids][]", :id="`shared_file_city_ids_${city[0]}`", :value="city[0]" :checked="cityIds.includes(city[0])")
          label.juno-checkbox-label.uk-form-label {{ city[1]}}
</template>
