<script setup lang="ts">
import { DownloadFile } from "@/models";

interface Props {
  downloadFile: DownloadFile
}
const props = defineProps<Props>();
</script>

<template lang="pug">
div
  .uk-flex
    .juno-download-one-file-text
      .uk-flex
        .juno-download-doc-name {{downloadFile.fileName}}
      div
        span 開始日時：{{downloadFile.downloadStartedAtSqlFormat}}
    .juno-download-one-file-icon
      div(v-if="downloadFile.isGenerating && !downloadFile.isTimeout" uk-spinner="ratio: 0.7" uk-tooltip="ダウンロード中")
      a(v-else-if="downloadFile.isFinished" :id="`download_file_${downloadFile.id}`" :href="downloadFile.fileUrl" :download="downloadFile.fileName")
        i.far.fa-arrow-alt-circle-down.fa-2x.uk-text-muted
      span.uk-text-muted(v-else uk-icon="icon: warning; ratio: 1.3" uk-tooltip="ダウンロードできません")
</template>
