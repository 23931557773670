import { ref } from "vue";

export default (props:any) => {
  const restrictedDonationUse = ref(props.restrictedDonationUse);
  const messageRestrictedDonationUse = ref(props.messageRestrictedDonationUse);
  const donationUses = ref(props.donationUses);
  const donationUseIds = ref(props.donationUseIds);

  return {
    restrictedDonationUse,
    messageRestrictedDonationUse,
    donationUses,
    donationUseIds,
  };
};