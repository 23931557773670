import { ref } from "vue";

export default (props:any) => {
  const thumbnailBlobId = ref(props.thumbnailBlobId);
  const thumbnailName = ref(props.thumbnailName);

  return {
    thumbnailBlobId,
    thumbnailName
  };
};
