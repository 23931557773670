import { ref } from "vue";

export default (props:any) => {
  const tmpPublicValue = props.selectedPublic === 'true' ? true : props.selectedPublic === 'false' ? false : null;

  const selectPublic = ref(tmpPublicValue);
  const cityIds = ref(props.currentCityIds);

  return {
    selectPublic,
    cityIds
  };
};
