import { ref } from "vue";

export default (props:any) => {
  const category = ref(props.category);
  const url = ref(props.url);

  return {
    category,
    url
  };
};
