import { Ref } from 'vue';

export default (formDataOrders:Array<number>, existDataLastOrder:Ref<number>, formDatas:Array<any>) => {
  const clickDelete = (index) =>{
    // 既に送信済みのデータの場合Destroyのデータを送信するようvalueを変更
    if (formDataOrders[index] <= existDataLastOrder.value) {
      let buttonValue = document.getElementById(`customer_survey_customer_survey_choices_attributes_${formDataOrders[index]}__destroy`);
      buttonValue.value = "1";
    }
    formDatas.splice(index, 1);
    formDataOrders.splice(index, 1);
  };
    
  return { clickDelete }
}