import axios from "@/libs/Axios";
import {AxiosResponse} from "axios";
import { ukNotify } from "@/composables/common/common/methods/mtdUIKit";
import { Ref } from 'vue';


export default (requestPath:string, topicId:number, confirmedTopicId:Ref<number|null>) => {
  const onToggle = async  (confirmed:boolean) => {
    try {
      if (confirmed) {
        createConfirmedTopic(confirmedTopicId);
      } else {
        destroyConfirmedTopic(confirmedTopicId);
      }
    } catch (error) {
      notifyError();
      return null;
    }
  }

  function createConfirmedTopic(confirmedTopicId:Ref<number|null>) {
    const params = {
      'confirmed_topic': {
        'topic_id': topicId
      }
    };
    // 確認済み問い合わせデータをcreate
    axios
      .post(requestPath, params)
      .then((response:AxiosResponse) => {
        const resData = response && response.data ? response.data : {};
        if (resData?.id) {
          confirmedTopicId.value = resData.id;
        } else {
          notifyError();
        }
      })
      .catch(() => {
        notifyError();
      });
  }

  function destroyConfirmedTopic(confirmedTopicId:Ref<number|null>) {
    // 確認済み問い合わせデータをcreate
    const destroyPath = `${requestPath}/${confirmedTopicId.value}`;
    axios.delete(destroyPath).then((_response:AxiosResponse) => {
      confirmedTopicId.value = null;
    });
  }

  function notifyError() {
    ukNotify('お知らせの確認済み状態を更新できませんでした。');
  }

  return { onToggle }
}
