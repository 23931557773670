<script setup lang="ts">
import refs from "@/composables/common/panels/topics/index/form_check_box_confirmed/refs";
import mtdOnToggle from "@/composables/common/panels/topics/index/form_check_box_confirmed/methods/mtdOnToggle";

interface Props {
  labelText:string,
  topicIdValue:number,
  confirmedTopicIdValue?:number|null,
  baseUrl:string
}
const props = withDefaults(defineProps<Props>(), {
  confirmedTopicIdValue: null
});

// == ref ==
const { confirmed, confirmedTopicId } = refs(props.confirmedTopicIdValue);
// == methods ==
const { onToggle } = mtdOnToggle(props.baseUrl, props.topicIdValue, confirmedTopicId);
</script>

<template lang="pug">
.form-group
  .uk-margin-small-right
    label.uk-flex
      .juno-checkbox-label.uk-margin-small-right
        span.uk-text-small {{ labelText }}
      .juno-checkbox
        input.uk-margin-remove(
          :id="`topic_confirmed_topics_topic_id_${topicIdValue}`"
          name="topic_confirmed_topics[topic_id]"
          type="checkbox"
          v-model="confirmed"
          @change="onToggle(confirmed)"
        )
</template>
