<script setup lang="ts">
import cmpOptionalStatements
  from "@/composables/common/panels/payment_statements/donations/index/common/computed/cmpOptionalStatements";

import { useStore, Store } from 'vuex';
import { key } from '@/vuex';
import type { State } from '@/vuex';
const store:Store<State> = useStore(key);

interface Props {
  labelText:string
}
const props = defineProps<Props>();

// == computed ==
const { optionalStatements } = cmpOptionalStatements(store);
</script>

<template lang="pug">
button#button_add_optional_statements.uk-button.uk-button-default(uk-toggle="target: #modal_add_optional_statements" type="button")
  span.uk-text-middle {{labelText}}
    span.uk-badge.juno-button-badge {{`${optionalStatements.length}件`}}
</template>
