import { BaseModel } from './'
import { DateTime } from 'luxon';

interface ITempPaymentSummary {
  id?: number;
  store_id?: number;
  store_name?: string;
  start_date?: DateTime | null;
  end_date?: DateTime | null;
  total_item_cost?: number;
  total_item_cost_with_tax8?: number,
  total_item_cost_with_tax10?: number,
  total_item_cost_tax_free?: number,
  total_optional_amount_with_tax8?: number,
  total_optional_amount_with_tax10?: number,
  total_tax8?: number,
  total_tax10?: number,
  payment_sum?: number,
  note?: string;
  include_out_of_term?: boolean;
  has_canceled_donations?: boolean;
  created_at?: DateTime;
  updated_at?: DateTime;
}

export class TempPaymentSummary extends BaseModel implements ITempPaymentSummary {
  constructor(data: ITempPaymentSummary) {
    super(data);
    if (data.start_date) this.assignAttribute('start_date', DateTime.fromISO(data.start_date));
    if (data.end_date) this.assignAttribute('end_date', DateTime.fromISO(data.end_date));
    if (data.created_at) this.assignAttribute('created_at', DateTime.fromISO(data.created_at));
    if (data.updated_at) this.assignAttribute('updated_at', DateTime.fromISO(data.updated_at));
  }

  defineDefaultValues () {
    this._defaultValues = {
      store_id: null,
      store_name: '',
      start_date: null,
      end_date: null,
      total_item_cost: 0,
      total_item_cost_with_tax8: 0,
      total_item_cost_with_tax10: 0,
      total_item_cost_tax_free: 0,
      total_optional_amount_with_tax8: 0,
      total_optional_amount_with_tax10: 0,
      total_tax8: 0,
      total_tax10: 0,
      payment_sum: 0,
      note: '',
      include_out_of_term: false,
      has_canceled_donations: false,
      created_at: null,
      updated_at: null
    };
  }

  // getters
  get id(): number { return this._data.id; }
  get storeId(): number { return this._data.store_id; }
  get storeName(): string { return this._data.store_name; }
  get startDate(): DateTime | null { return this._data.start_date; }
  get endDate(): DateTime | null { return this._data.end_date; }
  get totalItemCost(): string { return this._data.total_item_cost; }
  get totalItemCostWithTax8(): number { return this._data.total_item_cost_with_tax8; }
  get totalItemCostWithTax10(): number { return this._data.total_item_cost_with_tax10; }
  get totalItemCostTaxFree(): number { return this._data.total_item_cost_tax_free; }
  get totalOptionalAmountWithTax8(): number { return this._data.total_optional_amount_with_tax8; }
  get totalOptionalAmountWithTax10(): number { return this._data.total_optional_amount_with_tax10; }
  get totalTax8(): number { return this._data.total_tax10; }
  get totalTax10(): number { return this._data.total_tax10; }
  get paymentSum(): number { return this._data.payment_sum; }
  get note(): string { return this._data.note; }
  get includeOutOfTerm(): boolean { return this._data.include_out_of_term; }
  get hasCanceledDonations(): boolean { return this._data.has_canceled_donations; }
  get createdAt(): DateTime | null { return this._data.created_at; }
  get updatedAt(): DateTime | null { return this._data.updated_at; }
}
