import BinderBase from '@/binders/binder_base';
import ButtonRejection from '@/components/common/panels/items/common/button_rejection';

class ButtonRejectionBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#button_rejection',
        app: ButtonRejection
      }
    ];
  }
}

export default ButtonRejectionBinder;
