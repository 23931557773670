import { BaseModel } from './'
import { DateTime } from 'luxon';

interface IPaymentStatementSetting {
  id?: number;
  city_id?: number;
  city_name?: string;
  city_zip_cd?: string;
  city_address1?: string;
  city_address2?: string;
  city_address3?: string;
  city_tel?: string;
  city_contact_person?: string;
  document_title?: string;
  document_no_prefix?: string;
  donated_statement_name?: string;
  note?: string;
  cut_off_end_of_month?: boolean;
  cut_off_days?: string;
  created_at?: DateTime|null;
  updated_at?: DateTime|null;
}

export class PaymentStatementSetting extends BaseModel implements IPaymentStatementSetting {
  constructor(data: IPaymentStatementSetting) {
    super(data);
    if (data.created_at) this.assignAttribute('created_at', DateTime.fromISO(data.created_at));
    if (data.updated_at) this.assignAttribute('updated_at', DateTime.fromISO(data.updated_at));
  }

  defineDefaultValues () {
    this._defaultValues = {
      city_id: '',
      city_name: '',
      city_zip_cd: '',
      city_address1: '',
      city_address2: '',
      city_address3: '',
      city_tel: '',
      city_contact_person: '',
      document_title: '',
      document_no_prefix: '',
      donated_statement_name: '',
      note: '',
      cut_off_end_of_month: true,
      cut_off_days: '',
      created_at: null,
      updated_at: null
    };
  }

  // getters
  get id(): number | undefined { return this._data.id; }
  get cityId(): number { return this._data.city_id; }
  get cityName(): string { return this._data.city_name; }
  get cityZipCd(): string { return this._data.city_zip_cd; }
  get cityAddress1(): string { return this._data.city_address1; }
  get cityAddress2(): string { return this._data.city_address2; }
  get cityAddress3(): string { return this._data.city_address3; }
  get cityTel(): string { return this._data.city_tel; }
  get cityContactPerson(): string { return this._data.city_contact_person; }
  get documentTitle(): string { return this._data.document_title; }
  get documentNoPrefix(): string { return this._data.document_no_prefix; }
  get donatedStatementName(): string { return this._data.donated_statement_name; }
  get note(): string { return this._data.note; }
  get cutOffEndOfMonth(): boolean { return this._data.cut_off_end_of_month; }
  get cutOffDays(): string { return this._data.cut_off_days; }
  get createdAt(): DateTime | null { return this._data.created_at; }
  get updatedAt(): DateTime | null { return this._data.updated_at; }
}
