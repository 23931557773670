import { Store } from 'vuex';
import { State } from '@/vuex';

export default (store:Store<State>) => {
  const getOptionalStatements = (requestPath:string) => {
    const params = {
      requestPath
    };

    store.commit('optionalStatements/getOptionalStatements', params)
  }

  return { getOptionalStatements }
}
