import BinderBase from '@/binders/binder_base';
import ButtonAddOptionalStatements from '@/components/common/panels/payment_statements/donations/index/button_add_optional_statements';

class ButtonAddOptionalStatementsBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#btn_add_optional_statements',
        app: ButtonAddOptionalStatements
      }
    ];
  }
}

export default ButtonAddOptionalStatementsBinder;
