import { ref } from "vue";

export default (props:any) => {
  const redhorseMunicipalityCode = ref(props.redhorseMunicipalityCode);
  const redHorseIntegrationEnabled = ref(redhorseMunicipalityCode.value !== '')

  return {
    redhorseMunicipalityCode,
    redHorseIntegrationEnabled
  };
};
