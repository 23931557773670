import axios from "@/libs/Axios";
import { AxiosResponse } from 'axios';
import UIkit from 'uikit';
import { ukNotify } from "@/composables/common/common/methods/mtdUIKit";
import { Store } from 'vuex';
import type { State } from '@/vuex';
import { OptionalStatement } from "@/models";
import mtdFormatDate from "@/composables/common/common/methods/mtdFormatDate";

interface Emits {
  (e: 'update:storeIds', storeIds:string[]): void
  (e: 'update:recordedDate', recordedDate:Date|null): void
  (e: 'update:name', name:string): void
  (e: 'update:amount', amount:string): void
  (e: 'update:taxRate', taxRate:string): void
}
export default (store:Store<State>) => {
  const onClick = async (requestPath:string, storeIds:string[], recordedDate:Date|null, name:string, amount:string, taxRate:string, emit:Emits) => {
    try {
      // 支払項目データをcreate
      axios.post(requestPath, createParams(storeIds, recordedDate, name, amount, taxRate)).then((response:AxiosResponse) => {
        const resData = response && response.data ? response.data : {};
        const optionalStatement = new OptionalStatement(resData);
        // storeに追加
        store.commit('optionalStatements/push', optionalStatement);

        if (optionalStatement?.id) {
          clearForms(emit);
          notifySuccess();
        } else {
          notifyError();
        }
        return true;
      });
    } catch (error) {
      notifyError();
      return null;
    }
  }

  function createParams (storeIds:string[], recordedDate:Date|null, name:string, amount:string, taxRate:string) {
    const { formatDate } = mtdFormatDate();
    return {
      'optional_statement': {
        'store_ids': storeIds,
        'recorded_date': formatDate(recordedDate),
        'name': name,
        'amount': amount,
        'tax_rate': taxRate
      }
    };
  }

  function notifyError() {
    ukNotify('支払項目を追加できませんでした。');
  }

  function notifySuccess() {
    ukNotify('支払項目を登録しました。');
  }

  function clearForms(emit:Emits) {
    emit('update:storeIds', []);
    emit('update:recordedDate', null);
    emit('update:name', '');
    emit('update:amount', '');
    emit('update:taxRate', '');
  }

  return { onClick }
}
