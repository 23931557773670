import { ref } from "vue";

export default (payTypeGroupIdValue:number|null, payTypeIdValue:number|null) => {
  // 選択中のID
  const payTypeGroupId = ref(payTypeGroupIdValue || '');
  const payTypeId = ref(payTypeIdValue || '');

  return {
    payTypeGroupId,
    payTypeId
  };
};
