export default (donatedAtGteq:string, donatedAtLteq:string) => {
  const setDonatedAt = () => {
    // 寄附申込日・開始日を設定
    const elDonatedAtGteqDiv = document.getElementById('q_donated_at_gteq') as HTMLElement;
    const elDonatedAtGteqInput = elDonatedAtGteqDiv.getElementsByTagName('input')[0] as HTMLInputElement;
    elDonatedAtGteqInput.value = donatedAtGteq;

    // 寄附申込日・終了を設定
    const elDonatedAtLteqDiv = document.getElementById('q_donated_at_lteq_end_of_day') as HTMLElement;
    const elDonatedAtLteqInput = elDonatedAtLteqDiv.getElementsByTagName('input')[0] as HTMLInputElement;
    elDonatedAtLteqInput.value = donatedAtLteq;
  }

  return { setDonatedAt }
}
