import axios from "@/libs/Axios";
import { AxiosResponse } from 'axios';
import { ukNotify } from "@/composables/common/common/methods/mtdUIKit";
import { Store } from 'vuex';
import { State } from '@/vuex';
import { Ref } from "vue";
import mtdFormatDate from "@/composables/common/common/methods/mtdFormatDate";

export default (store:Store<State>, approvalDate:Ref<string>) => {
  const onClick = async (baseUrl:string, itemId:number) => {
    // 処理中はボタンをdisabledに
    store.commit('items/switchToggle')
    const updatePath = `${baseUrl}/${itemId}`
    axios
      .put(updatePath, updateParams(itemId))
      .then((response:AxiosResponse) => {
        const resData = response && response.data ? response.data : {};
        if (resData?.id) {
          approvalDate.value = resData.approval_date;
        } else {
          notifyError();
        }
        // 処理終了のタイミングでボタンをactiveに
        store.commit('items/switchToggle')
      })
      .catch(() => {
        notifyError();
      });
  }

  function updateParams (itemId: number) {
    const { formatDate } = mtdFormatDate();
    return {
      'item' : {
        'id' : itemId,
        'approval_date' : formatDate(new Date)
      }
    };
  }

  function notifyError() {
    ukNotify('承認に失敗しました。');
  }
  return { onClick }
}
