import { Store } from 'vuex';
import { State } from '@/vuex';

export default (store:Store<State>) => {
  const getDownloadFiles = (requestPath:string) => {
    // APIからダウンロードファイル情報を取得
    store.commit('downloadFiles/getDownloadFiles', requestPath)
  }

  return { getDownloadFiles }
}
