import { ref, Ref } from "vue";

export default (receivedAtValue?:string, bodyValue?:string) => {
  // 入力中の値
  const tmpReceivedAt = receivedAtValue ? new Date(Date.parse(receivedAtValue)) : null;
  const receivedAt:Ref<Date|null> = ref(tmpReceivedAt);

  const tmpBody = bodyValue ? bodyValue : '';
  const body:Ref<string> = ref(tmpBody);

  return {
    receivedAt,
    body
  };
};
