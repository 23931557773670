import { computed } from "vue"
import { Store } from 'vuex';
import { State } from '@/vuex';

export default (store:Store<State>) => {
  return {
    downloadFiles: computed(() => store.state.downloadFiles.downloadFiles),
    isGettingDlFiles: computed(() => store.state.downloadFiles.isGettingList),
    inquiries: computed(() => store.state.inquiries.inquiries),
    optionalStatements: computed(() => store.state.optionalStatements.optionalStatements),
    paymentStatementSetting: computed(() => store.state.paymentStatementSetting.paymentStatementSetting),
    tempPaymentSummaries: computed(() => store.state.tempPaymentSummaries.tempPaymentSummaries),
    paymentStatementsNoRange: computed(() => store.state.tempPaymentSummaries.paymentStatementsNoRange),
    paymentStatementsTerm: computed(() => store.state.tempPaymentSummaries.paymentStatementsTerm),
    isApproveLoading: computed(() => store.state.items.isApproveLoading),
  }
}
