<script setup lang="ts">
import cmpDisabled from "@/composables/common/panels/payment_statements/donations/index/button_clear_optional_statement/computed/cmpDisabled";
import {toRefs} from "vue";

interface Props {
  id:string,
  storeIds:number[],
  recordedDate:Date|null,
  name:string,
  amount:string,
  taxRate:string,
}
const props = withDefaults(defineProps<Props>(), {
});

const emit = defineEmits(['clear']);

// == ref ==
const { id, storeIds, recordedDate, name, amount, taxRate } = toRefs(props);

// == computed ==
const { disabled } = cmpDisabled(id, storeIds, recordedDate, name, amount, taxRate);

// == methods ==
const onClick = () => { emit("clear") };
</script>

<template lang="pug">
button#button_clear_optional_statement.uk-button.uk-button-default(
  type="button"
  :disabled="disabled"
  @click="onClick"
) クリア
</template>
