import { ref } from "vue";

export default () => {
  const disabled = ref(true);
  const originalRejectionReason = ref('');
  const editedRejectionReason = ref('');
  
  return {
    disabled,
    originalRejectionReason,
    editedRejectionReason
  };
};
