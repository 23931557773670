import { reactive, ref } from "vue";

export default () => {
  const message = ref('');

  const state = reactive({
    count: message.value.length,
    isWithinCharacterLimit: true
  })

  return {
    message,
    state,
  };
};