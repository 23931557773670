import axios from "@/libs/Axios";
import { AxiosResponse } from 'axios';
import { ukNotify } from "@/composables/common/common/methods/mtdUIKit";
import { PaymentStatementSetting } from "@/models";
import { paymentStatementSettingState } from '@/vuex/store/paymentStatementSetting';

interface Arguments {
  requestPath:string;
}

export const getPaymentStatementSetting = (state:paymentStatementSettingState, args:Arguments) => {
    axios
      .get(args.requestPath, {})
      .then((response:AxiosResponse) => {
        const resData = response && response.data ? response.data[0] : {}; // 支払通知設定は１件のみ返却される
        state.paymentStatementSetting = new PaymentStatementSetting(resData);
      })
      .catch(() => {
        ukNotify('支払通知設定を取得できませんでした。');
      });
}
