import { DateTime } from 'luxon';

export class Inquiry {
  private _id: number | null = null;
  private _receivedAt: DateTime | null = null;
  private _body: string = '';
  private _userName: string = '';

  constructor(private _orgInstance: any | null) {
    if (_orgInstance) {
      this._id = _orgInstance.id;
      if (_orgInstance.received_at) this._receivedAt = DateTime.fromISO(_orgInstance.received_at);
      this._body = _orgInstance.body;
      this._userName = _orgInstance.user_name;
    }
  }

  // getters
  get id() {
    return this._id;
  }

  get receivedAt() {
    return this._receivedAt;
  }

  get body() {
    return this._body;
  }

  get userName() {
    return this._userName;
  }

  // 'YYYY-MM-DD hh:mm:ss'フォーマット文字列
  get receivedAtSqlFormat () { return this._receivedAt?.toFormat('yyyy-MM-dd TT'); }

}
