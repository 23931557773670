import { Store } from 'vuex';
import { State } from '@/vuex';

export default (store:Store<State>) => {
  const getInquiries = (requestPath:string, donationId:number) => {
    // APIからダウンロードファイル情報を取得
    const params = {
      requestPath,
      donationId
    };

    store.commit('inquiries/getInquiries', params)
  }

  return { getInquiries }
}
