import Axios from "axios";

const tokenElement:Element|null = document.querySelector("meta[name=csrf-token]");
const token:string|null = tokenElement ? tokenElement.getAttribute('content') : null;

const axios = Axios.create({
  baseURL: '/api/v1',
  headers: {
    'Content-Type': 'application/json',
    'X-CSRF-TOKEN':  token || ''
  },
  withCredentials: true
});
axios.defaults.timeout = 25000; // axiosデフォルトタイムアウト時間（ミリ秒）

export default axios;
