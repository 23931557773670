import BinderBase from '@/binders/binder_base';
import ModalIssuePaymentStatement from '@/components/common/panels/payment_statements/donations/index/modal_issue_payment_statement';

class ModalIssuePaymentStatementBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#modal_issue_payment_statement',
        app: ModalIssuePaymentStatement
      }
    ];
  }
}

export default ModalIssuePaymentStatementBinder;
