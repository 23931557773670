import { computed } from "vue"
import { Store } from 'vuex';
import { State } from '@/vuex';
import cmpStoreState from "@/composables/common/common/computed/cmpStoreState";

export default (store:Store<State>) => {
  const storeInquiries = cmpStoreState(store).inquiries;
  return {
    inquiries: computed(() => {
      return storeInquiries.value.sort((a, b) => a.receivedAt < b.receivedAt ? 1 : -1);
    })
  }
}
