interface Emits {
  (e: 'update:modelValue', modelValue:string): void
}
export default (emit:Emits) => {
  const onInput = async (event:Event) => {
    const target = event.target as HTMLInputElement;
    const sign = target.value[0] === '-' ? '-' : ''; // 数値以外では、先頭のマイナスのみ許可
    const value = target.value.replace(/[^0-9]/g, ''); // 数字以外を削除
    const replacedValue = sign + value;

    emit('update:modelValue', replacedValue);
    target.value = replacedValue; // 親に通知するだけでは置き換わらないので明示的に代入
  }

  return { onInput }
}
