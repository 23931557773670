<script setup lang="ts">
import ButtonDownload from "@/components/common/buttons/button_download.vue";
import refs from "@/composables/common/panels/common/index/refs";

interface Props {
  buttonText: string; // 入力フォームのラベルテキスト
  requestPath: string; // リクエストパス
  dataDiv: string; // データ種別
  target_ids: string; // 対象データのid(配列をシリアライズした文字列）
}
const props = defineProps<Props>();

// == ref ==
const { extension } = refs('csv'); // 2022.02.17時点、csvのみ
</script>

<template lang="pug">
.uk-flex.uk-flex-center.uk-margin-small-bottom
  .uk-margin-small-left
    ButtonDownload(
      :button-text="buttonText"
      :request-path="requestPath"
      :data-div="dataDiv"
      :extension="extension"
      :target_ids="target_ids"
      file-label="寄附情報CSVファイル"
    )
</template>
