import { BaseModel } from './'
import { DateTime } from 'luxon';

interface IOptionalStatement {
  id?: number;
  city_id?: number;
  recorded_date?: DateTime | null;
  name?: string;
  amount?: number;
  tax_rate?: number;
  created_at?: DateTime;
  updated_at?: DateTime;
  storeIds?: number[];
}

export class OptionalStatement extends BaseModel implements IOptionalStatement {
  constructor(data: IOptionalStatement) {
    super(data);
    if (data.recorded_date) this.assignAttribute('recorded_date', DateTime.fromISO(data.recorded_date));
    if (data.created_at) this.assignAttribute('created_at', DateTime.fromISO(data.created_at));
    if (data.updated_at) this.assignAttribute('updated_at', DateTime.fromISO(data.updated_at));
  }

  defineDefaultValues () {
    this._defaultValues = {
      cityId: null,
      recordedDate: null,
      name: '',
      amount: 0,
      taxRate: null,
      createdAt: null,
      updatedAt: null,
      storeIds: []
    };
  }

  // getters
  get id(): number { return this._data.id; }
  get cityId(): number { return this._data.city_id; }
  get recordedDate(): DateTime | null { return this._data.recorded_date; }
  get name(): string { return this._data.name; }
  get amount(): number { return this._data.amount; }
  get taxRate(): number | null { return this._data.tax_rate; }
  get createdAt(): DateTime | null { return this._data.created_at; }
  get updatedAt(): DateTime | null { return this._data.updated_at; }
  get storeIds(): number[] { return this._data.store_ids; }

  // 計上日（書式変換済み）
  get formattedRecordedDate(): string {
    return this._data.recorded_date ? this._data.recorded_date.toFormat('yyyy-MM-dd') : '';
  }
  // 金額（カンマ区切り）
  get delimitedAmount(): string {
    return this._data.amount ? this._data.amount.toLocaleString() : '';
  }
}
