<script setup lang="ts">
import mtdClearSearch from "@/composables/common/common/methods/mtdClearSearch";

interface Props {
  buttonText: String; // ラベルテキスト
}
const props = defineProps<Props>();

// == methods ==
const { clearSearch } = mtdClearSearch();
</script>

<template lang="pug">
button.uk-button.uk-button-default.uk-text-nowrap(@click.prevent="clearSearch") {{ buttonText }}
</template>
