<script setup lang="ts">
import FormText from "@/components/common/input_forms/form_text.vue";

interface Props {
  index: number,
  formData: Array<any>,
  formDataOrders: Array<number>
}
const props = defineProps<Props>();
</script>

<template lang="pug">
.uk-grid(class="uk-child-width-1-1@m uk-child-width-1-2@l")
  div
    .uk-margin-small-bottom
      .form-group.uk-flex
        .uk-form-horizontal.uk-margin-small-right
          label.uk-form-label.uk-flex 表示文言
            span.uk-label-danger.juno-required-label 必須
          .uk-form-controls.uk-flex
            input.uk-input(
              :id="`customer_survey_customer_survey_choices_attributes_${formDataOrders[index]}_display_text`"
              :name="`customer_survey[customer_survey_choices_attributes][${formDataOrders[index]}][display_text]`"
              v-model="formData.display_text"
              )
  div
    .uk-margin-small-bottom
      .form-group.uk-flex
        .uk-form-horizontal.uk-margin-small-right
          label.uk-form-label.uk-flex 順番
            span.uk-label-danger.juno-required-label 必須
          .uk-form-controls.uk-flex
            input.uk-input(
              :id="`customer_survey_customer_survey_choices_attributes_${formDataOrders[index]}_order`"
              :name="`customer_survey[customer_survey_choices_attributes][${formDataOrders[index]}][order]`"
              v-model="formData.order"
              )
            input.uk-input(
              :id="`customer_survey_customer_survey_choices_attributes_${formDataOrders[index]}_id`"
              :name="`customer_survey[customer_survey_choices_attributes][${formDataOrders[index]}][id]`"
              :value="formData.id"
              type="hidden"
              )
</template>
