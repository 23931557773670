import { getPaymentStatementSetting } from '@/vuex/store/paymentStatementSetting/mutations/mutGetPaymentStatementSetting';
import { PaymentStatementSetting } from "@/models";

const state = () => {
  return {
    paymentStatementSetting: null, // 支払通知設定
  }
};

export interface paymentStatementSettingState {
  paymentStatementSetting: PaymentStatementSetting,
}

export const paymentStatementSetting = {
  namespaced: true,
  state,
  mutations: {
    'getPaymentStatementSetting': getPaymentStatementSetting,
  }
};
