<script setup lang="ts">
import mtdOnClick from "@/composables/common/panels/payment_statements/donations/index/button_create_payment_statement/methods/mtdOnClick";

import { useStore, Store } from 'vuex';
import { key } from '@/vuex';
import type { State } from '@/vuex';
const store:Store<State> = useStore(key);

interface Props {
  requestPath:string
  disabled:boolean
  issueDate:Date
}
const props = withDefaults(defineProps<Props>(), {
  disabled: true
});

const emit = defineEmits(['create']);

// == methods ==
const { onClick } = mtdOnClick(store, props.requestPath, emit);
</script>

<template lang="pug">
button#button_create_payment_statement.uk-button.uk-button-default(
  type="button"
  :disabled="disabled"
  @click="onClick(issueDate)"
) 発行
</template>
