import BinderBase from '@/binders/binder_base';
import FormPublic from '@/components/common/panels/shared_files/form_public';

class FormPublicBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#form_public',
        app: FormPublic
      }
    ];
  }
}

export default FormPublicBinder;
