import BinderBase from '@/binders/binder_base';
import FormCheckBoxConfirmed from '@/components/common/panels/topics/index/form_check_box_confirmed';

class FormCheckBoxConfirmedBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '.form-check-box-confirmed',
        app: FormCheckBoxConfirmed
      }
    ];
  }
}

export default FormCheckBoxConfirmedBinder;
