<script setup lang="ts">
import refs from "@/composables/common/input_forms/form_select_polymorphic_l/refs";
import cmpObjectList from "@/composables/common/input_forms/form_select_polymorphic_l/computed/cmpObjectList";
import watchSelectedType from "@/composables/common/input_forms/form_select_polymorphic_l/watch/watchSelectedType";

interface  Props {
  labelText: string; // 入力フォームのラベルテキスト
  modelName: string; // 自model名
  columnKey: string; // xxxable_typeのxxxableの部分
  objectTypes: any; // xxxable_typeの内訳（[名称, 値]の配列）
  objectLists: any; // typeごとのxxxable_idの内訳（{ type: objectTypesの値, objects: 対象modelの[名称, 値] }の配列）
  objectTypeValue: string|null; // 現在のxxxable_typeの値
  objectIdValue: number|null; // 現在のxxxable_idの値
  disabled: boolean; // 入力を許可するかどうか
  requiredLabel: boolean; // 必須ラベルの表示
}
const props = withDefaults(defineProps<Props>(), {
  objectTypeValue: null,
  objectIdValue: null,
  disabled: false
});

// == ref ==
const { selectedType, selectedId } = refs(props.objectTypeValue, props.objectIdValue, props.objectTypes[0][1]);
// 選択中xxxable_typeに対応するxxxable_id情報の配列
const objectList = cmpObjectList(props.objectLists, selectedType);
// == watch ==
watchSelectedType(selectedType, selectedId);
</script>

<template lang="pug">
.form-group.uk-flex
  .uk-form-horizontal.uk-margin-small-right
    label.string.uk-form-label.uk-flex
      |  {{ labelText }}
      span.uk-label-danger.juno-required-label(v-if="requiredLabel") 必須

    .uk-form-controls
      .input.select.field_without_errors(:class="`${columnKey}_type`")
        select.is-valid.select.uk-select.uk-form-width-medium(
          v-model="selectedType"
          :id="`${modelName}_${columnKey}_type`"
          :name="`${modelName}[${columnKey}_type]`"
          :disabled="disabled"
        )
          option(v-for="type in objectTypes" :value="type[1]") {{type[0]}}
  .uk-form-controls.juno-select-polymorphic-l
    .input.select(:class="`${modelName}_${columnKey}_id`")
      select.select.uk-select.juno-form-width-m(
        v-model="selectedId"
        :id="`${modelName}_${columnKey}_id`"
        :name="`${modelName}[${columnKey}_id]`"
        :disabled="disabled"
      )
        option(v-for="obj in objectList" :value="obj[1]") {{obj[0]}}
</template>
