<script setup lang="ts">
import mtdCreateFile from "@/composables/common/buttons/button_download/methods/mtdCreateFile";
import cmpStoreState from "@/composables/common/common/computed/cmpStoreState";

import { useStore, Store } from 'vuex';
import { key } from '@/vuex';
import type { State } from '@/vuex';
const store:Store<State> = useStore(key);

interface Props {
  buttonText: string; // 入力フォームのラベルテキスト
  requestPath: string; // リクエストパス
  dataDiv: string; // データ種別
  extension: string; // 選択中の値
  target_ids: string; // 対象データのid(配列をシリアライズした文字列）
  fileLabel: string; // ファイル名（日本語）
}
const props = defineProps<Props>();

// == computed ==
const { downloadFiles } = cmpStoreState(store);
// == methods ==
const { createFile } = mtdCreateFile(store, props.fileLabel);
</script>

<template lang="pug">
button.uk-button.uk-button-default.uk-text-nowrap(@click.prevent="createFile(requestPath, dataDiv, extension, target_ids)") {{ buttonText }}
</template>
