import { Ref } from "vue";

export default (receivedAtRef:Ref<Date|null>, bodyRef:Ref<string>) => {
  const clearModalForms = () => {
    // refsをクリア
    receivedAtRef.value = null;
    bodyRef.value = '';
  }

  return {
    clearModalForms
  }
}
