<script setup lang="ts">
import { watch } from 'vue';
import refs from "@/composables/common/panels/cities/form_redhorse_integration_setting/refs";
interface Props {
  redhorseMunicipalityCode: string | null;
}
const props = defineProps<Props>();
// == ref ==
const { redhorseMunicipalityCode, redHorseIntegrationEnabled } = refs(props);

watch(redHorseIntegrationEnabled, (newValue: boolean) => {
  if (newValue === false) redhorseMunicipalityCode.value = null;
});
</script>
<template lang="pug">
.uk-margin-small-bottom
  .form-group.uk-flex
    .uk-form-horizontal
      .uk-form-label
        label レッドホースとの寄附情報連携を行う
      .uk-form-controls.juno-radio-buttons.uk-flex
        label.juno-radio-button
          input(type="radio" :value="true" v-model="redHorseIntegrationEnabled" id="redhorse_form_display_availability_true")
        .juno-radio-button-label
          | 行う
        label.juno-radio-button
          input(type="radio" :value="false" v-model="redHorseIntegrationEnabled" id="redhorse_form_display_availability_false")
        .juno-radio-button-label
          | 行わない
.uk-margin-small-bottom(v-if="redHorseIntegrationEnabled")
  .form-group.uk-flex
    .uk-form-horizontal
      .uk-form-label.uk-flex
        label 連携用自治体コード
        span.uk-label-danger.juno-required-label 必須
      .uk-form-controls
        input.uk-input.uk-form-width-medium(v-model="redhorseMunicipalityCode", name="city[city_setting_attributes][redhorse_municipality_code]", id="city_city_setting_attributes_redhorse_municipality_code")
  .uk-text-small.uk-text-muted
    span.uk-margin-small-right(uk-icon="info")
    span.uk-text-middle レッドホース社のシステムに登録されている自治体コードを入力してください
input(v-else type="hidden" name="city[city_setting_attributes][redhorse_municipality_code]", id="city_city_setting_attributes_redhorse_municipality_code" :value="null")
.uk-text-small.uk-text-muted
  span.uk-margin-small-right(uk-icon="info")
  span.uk-text-middle 毎日午前1時に個人情報登録日時が前日分のものが連携されます
</template>