import { getTempPaymentSummaries } from '@/vuex/store/tempPaymentSummaries/mutations/mutGetTempPaymentSummaries';
import { push } from '@/vuex/store/tempPaymentSummaries/mutations/mutPush';
import { remove } from '@/vuex/store/tempPaymentSummaries/mutations/mutRemove';
import { splice } from '@/vuex/store/tempPaymentSummaries/mutations/mutSplice';
import { TempPaymentSummary } from "@/models";

const state = () => {
  return {
    paymentStatementsNoRange: '', // 通知書番号の範囲
    paymentStatementsTerm: '', // 対象期間
    tempPaymentSummaries: null, // 支払通知集計データ（仮登録）・・・未取得と0件を区別するため、初期値は[]ではなくnull
  }
};

export interface tempPaymentSummariesState {
  paymentStatementsNoRange: string;
  paymentStatementsTerm: string;
  tempPaymentSummaries: TempPaymentSummary[]
}

export const tempPaymentSummaries = {
  namespaced: true,
  state,
  mutations: {
    'getTempPaymentSummaries': getTempPaymentSummaries,
    'push': push,
    'remove': remove,
    'splice': splice
  }
};
