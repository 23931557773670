import { computed, Ref } from "vue"

export default (selectedMonth: Ref<number>) => {
  return computed(() => {
    let lastOfMonth = null
    if (selectedMonth.value == 2) {
      // 2月29日は選択できなくてOK
      lastOfMonth = 28
    } else if (selectedMonth.value == 4 || selectedMonth.value == 6 || selectedMonth.value == 9 || selectedMonth.value == 11) {
      lastOfMonth = 30
    } else {
      lastOfMonth = 31
    }
    return [...Array(lastOfMonth)].map((_, i) => i + 1)
  });
};
