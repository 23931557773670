import BinderBase from '@/binders/binder_base';
import FormCalcDonationAmount from '@/components/common/panels/items/common/form_calc_donation_amount';

class FormCalcDonationAmountBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#form_calc_donation_amount',
        app: FormCalcDonationAmount
      }
    ];
  }
}

export default FormCalcDonationAmountBinder;
