<script setup lang="ts">
import refs from "@/composables/common/panels/items/common/modal_rejection/refs";
import mtdOnClick from "@/composables/common/panels/items/common/modal_rejection/methods/mtdOnClick";
import mtdClearModalForms from "@/composables/common/panels/items/common/modal_rejection/methods/mtdClearModalForms";
import FormTextarea from "@/components/common/input_forms/form_textarea.vue";
import ButtonCloseModal from "@/components/common/buttons/button_close_modal.vue";

import { onMounted } from 'vue';
import { watch } from 'vue';

interface Props {
  idKey:string
  requestPath:string,
  itemId:number,
  itemRejectionReason:string
}
const props = withDefaults(defineProps<Props>(), {
  itemRejectionReason: null
});
// == ref ==
const { disabled, originalRejectionReason, editedRejectionReason } = refs();

// == methods ==
const { clearModalForms } = mtdClearModalForms(editedRejectionReason, originalRejectionReason);
const { onClick } = mtdOnClick(disabled);

onMounted (() => {
originalRejectionReason.value = props.itemRejectionReason;
editedRejectionReason.value = props.itemRejectionReason;
})

// == watch
// 差し戻し理由が変更されたら差し戻しボタンを押下できるように
watch(editedRejectionReason, (newVal: string) => {
  disabled.value = newVal === originalRejectionReason.value;
});
</script>

<template lang="pug">
div(:id="idKey" uk-modal)
  .uk-modal-dialog.uk-modal-body.juno-modal
    .juno-modal-title
      h4.uk-margin-medium-bottom 差し戻し
    .juno-modal-body
      FormTextarea(
        labelText="差し戻し理由"
        :required="false"
        modelName="item"
        columnName="rejection_reason"
        v-model="editedRejectionReason"
        :rows="10"
        :cols="100"
      )
    .juno-modal-footer.uk-flex.uk-flex-right
      .uk-margin-small-right
        button#item_rejection_submit.uk-button.uk-button-default(
          type="button"
          :disabled="disabled"
          @click="onClick(requestPath, itemId, editedRejectionReason)"
        ) 送信
      ButtonCloseModal(idKey="button_close_rejection_modal" label-text="キャンセル" @click="clearModalForms")
</template>
