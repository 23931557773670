<script setup lang="ts">
import cmpStoreState from "@/composables/common/common/computed/cmpStoreState";
import TableData from "@/components/common/panels/table_data.vue";

import { useStore, Store } from 'vuex';
import { key } from '@/vuex';
import type { State } from '@/vuex';
const store:Store<State> = useStore(key);

// == computed ==
const { tempPaymentSummaries } = cmpStoreState(store);
</script>

<template lang="pug">
.uk-overflow-auto.juno-inquiries-table.uk-background-default
  table#table_temp_payment_summaries.uk-table.uk-table-small.uk-table-striped.uk-table-hover.uk-table-divider.uk-margin-remove-bottom
    thead
      tr.juno-inquiries-table-header
        th.uk-text-center.uk-text-nowrap.uk-width-medium
          | 店舗名
        th.uk-text-center
        th.uk-text-center.uk-text-nowrap.uk-width-small
          | 合計額（税込）
        th.uk-text-center.uk-text-nowrap.uk-width-expand
          | 備考

    tbody
      tr(v-for="summary in tempPaymentSummaries" :key="summary.id")
        TableData(:value="summary.storeName" :ellipsis="true" cell-class="uk-width-medium" :tooltip="summary.storeName")
        TableData(cell-class="uk-text-center uk-flex uk-flex-middle juno-th-width-small uk-padding-remove")
          span.uk-text-danger(v-if="summary.includeOutOfTerm" uk-icon="icon: warning" uk-tooltip="title: 対象期間外の金額が含まれます")
        TableData(:value="(summary.paymentSum).toLocaleString()" cell-class="uk-text-right uk-margin-small-right")
        TableData(:value="summary.note" :ellipsis="true" cell-class="uk-width-expand" :tooltip="summary.note")
</template>
