import { ref } from "vue";

export default () => {
  const id = ref('');
  const storeIds = ref([]);
  const recordedDate = ref(null);
  const name = ref('');
  const amount = ref('');
  const taxRate = ref('');

  return {
    id,
    storeIds,
    recordedDate,
    name,
    amount,
    taxRate
  };
};
