import BinderBase from '@/binders/binder_base';
import FormAmountDetails from '@/components/common/panels/coupons/form_amount_details';

class FormAmountDetailsBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#form_amount_details',
        app: FormAmountDetails
      }
    ];
  }
}

export default FormAmountDetailsBinder;