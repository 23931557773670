import { BaseModel } from './'
import { DateTime } from 'luxon';

interface IMailCustomTemplate {
  id?:number;
  organizable_type?:string;
  organizable_id?:number;
  mail_from?:string;
  reply_to?:string;
  subject?:string;
  body?:string;
  discarded_at?:DateTime|null;
  created_at?:DateTime|null;
  updated_at?:DateTime|null;
}

export default class MailCustomTemplate extends BaseModel implements IMailCustomTemplate {
    constructor(data: IMailCustomTemplate) {
      super(data);
      if (data.created_at) this.assignAttribute('created_at', DateTime.fromISO(data.created_at));
      if (data.updated_at) this.assignAttribute('updated_at', DateTime.fromISO(data.updated_at));
    }

    defineDefaultValues () {
      this._defaultValues = {
        id: 0,
        organizableType: '',
        organizableId: 0,
        mailFrom: '',
        replyTo: '',
        subject: '',
        body: '',
        discardedAt: null,
        createdAt: null,
        updatedAt: null
      };
    }

    // getters
    get id(): number { return this._data.id; } // ID
    get organizableType(): string { return this._data.organizable_type; } // 組織種別
    get organizableId(): number { return this._data.organizable_id; } // 組織ID
    get mailFrom(): string { return this._data.mail_from; } // 送信元メールアドレス
    get replyTo(): string { return this._data.reply_to; } // 返信先メールアドレス
    get subject(): string { return this._data.subject; } // メールタイトル
    get body(): string { return this._data.body; } // メール本文
    get discardedAt(): DateTime|null { return this._data.discarded_at; } // 削除日時
    get createdAt(): DateTime|null { return this._data.created_at; } // 作成日時
    get updatedAt(): DateTime|null { return this._data.updated_at; } // 更新日時
}
