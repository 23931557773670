import BinderBase from '@/binders/binder_base';
import PanelInquiriesTable from '@/components/common/panels/donations/edit/panel_inquiries_table';

class PanelInquiriesTableBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#panel_inquiries_table',
        app: PanelInquiriesTable
      }
    ];
  }
}

export default PanelInquiriesTableBinder;
