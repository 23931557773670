import BinderBase from '@/binders/binder_base';
import FormSelectStore from '@/components/common/input_forms/form_select_store';

class FormSelectStoreBinder extends BinderBase {
  constructor () {
    super();
    this.targets = [
      {
        el: '#form_select_store',
        app: FormSelectStore
      }
    ];
  }
}

export default FormSelectStoreBinder;
