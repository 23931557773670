<script setup lang="ts">
import refs from "@/composables/common/panels/coupons/form_amount_details/refs";
import FormSelect from "@/components/common/input_forms/form_select.vue";

interface Props {
  selectAmountSettingOptions: any,
  amountSetting: Number;
  separateDonationAmount: Number;
}
const props = defineProps<Props>();

// == ref ==
const { selectAmountSettingOptions, amountSetting, separateDonationAmount } = refs(props);

</script>
<template lang="pug">
.uk-margin-small-bottom
  .form-group.uk-flex
    .uk-form-horizontal.uk-margin-small-right
      FormSelect(
          v-model="amountSetting"
          label-text="金額設定"
          place-holder=""
          model-name="coupon"
          column-name="amount_setting"
          :list="selectAmountSettingOptions"
          :required-label="true"
          class-names="uk-form-width-auto"
        )
.uk-margin-small-bottom(v-if="amountSetting == 'per_donation_amount'")
  .form-group.uk-flex
    .uk-form-horizontal
      .uk-form-label.uk-flex
        label 寄附額しきい値
        span.uk-label-danger.juno-required-label 必須
      .uk-form-controls
        input.uk-input.uk-form-width-medium(v-model="separateDonationAmount", name="coupon[separate_donation_amount]", id="coupon_separate_donation_amount")
  .uk-flex.uk-flex-middle
    p.uk-margin-small-right(uk-icon="info")
    p.uk-text-small 例：金額が10円、しきい値が1000円、寄附金額が10000円の場合、割引金額は、100円になります
</template>