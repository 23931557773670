import { ref } from "vue";

export default (objectTypeValue:string|null, objectIdValue:number|null, initialTypeValue:string) => {
  // 選択中xxxable_type
  const selectedType = ref(objectTypeValue ? objectTypeValue : initialTypeValue);
  // 選択中xxxable_id
  const selectedId = ref(objectIdValue);

  return {
    selectedType,
    selectedId
  };
};
