<script setup lang="ts">
import cmpStoreState from "@/composables/common/common/computed/cmpStoreState";
import cmpDisabled
  from "@/composables/common/panels/payment_statements/donations/index/modal_issue_payment_statement/computed/cmpDisabled";
import cmpTargetFile
  from "@/composables/common/panels/payment_statements/donations/index/modal_issue_payment_statement/computed/cmpTargetFile";
import mtdOnCreate
  from "@/composables/common/panels/payment_statements/donations/index/modal_issue_payment_statement/methods/mtdOnCreate";

import ButtonCloseModal from "@/components/common/buttons/button_close_modal.vue";
import ButtonCreatePaymentStatement
  from "@/components/common/panels/payment_statements/donations/index/button_create_payment_statement.vue";
import FormLabel from "@/components/common/input_forms/form_label.vue";
import FormDate from "@/components/common/input_forms/form_date.vue";
import TableTempPaymentSummaries
  from "@/components/common/panels/payment_statements/donations/index/table_temp_payment_summaries.vue";

import { ref, computed, watch } from "vue";
import { useStore, Store } from 'vuex';
import { key } from '@/vuex';
import type { State } from '@/vuex';
const store:Store<State> = useStore(key);

interface Props {
  requestPath:string
}
const props = withDefaults(defineProps<Props>(), {});

// == refs ==
const issueDate = ref(new Date);
const targetFileId = ref(0);

// == computed ==
const { paymentStatementSetting, tempPaymentSummaries, paymentStatementsNoRange, paymentStatementsTerm, downloadFiles } = cmpStoreState(store);
const { disabled } = cmpDisabled(paymentStatementSetting, tempPaymentSummaries, issueDate);
const { targetFile } = cmpTargetFile(store, targetFileId);
const bgClose = computed(() => targetFile.value ? 'false' : 'true');
const isFinished = computed(() => targetFile.value && targetFile.value.isFinished);

// == methods ==
const { onCreate } = mtdOnCreate(targetFileId); // 「発行」ボタン押下（通知書作成開始）

// == watch ==
watch(isFinished, (newVal) => {
  if (newVal) location.href = location.pathname; // 作成完了したら、検索パラメータなしでリロード
});
</script>

<template lang="pug">
#confirm_issue_payment_statement.uk-modal-container(:uk-modal="`bg-close: ${bgClose};`")
  .uk-modal-dialog(v-if="targetFile" key="isGenerating")
    // 作成中（作成が完了してもモーダルを表示したまま、再読み込みを行う）
    .uk-modal-body
      .juno-modal-title.uk-margin-medium-bottom
        span 支払通知書を作成中です。しばらくお待ちください。
        br
        span このメッセージが消えたら、画面右上の
        span.uk-margin-small-left.uk-margin-small-right(uk-icon="icon: cloud-download")
        span からダウンロードできます。
      .uk-width-expand.uk-text-center(uk-spinner)

  .uk-modal-dialog(v-else key="notGenerating")
    // 読み込み完了
    .uk-modal-body(v-if="paymentStatementSetting && tempPaymentSummaries" key="modal_ready")
      .juno-modal-title.uk-margin-medium-bottom
        span(v-if="tempPaymentSummaries.length > 0" key="payment_statements_exist") 支払通知書を発行します。よろしいですか？
        span(v-else key="no_payment_statements") 対象期間内の支払データはありません。
      .juno-modal-body
        .uk-margin-small-bottom
          span 【基本情報】
          .uk-grid(class="uk-child-width-1-1@m uk-child-width-1-2@l")
            div
              .uk-margin-medium-left
                FormLabel(label-text="文書名" :label-value="paymentStatementSetting.documentTitle")
              .uk-margin-medium-left
                FormLabel(label-text="対象期間" :label-value="paymentStatementsTerm")
            div
              .uk-margin-medium-left
                FormDate(
                  v-if="tempPaymentSummaries.length > 0"
                  key="display_issue_date"
                  v-model="issueDate"
                  label-text="発行日"
                  model-name="payment_statement"
                  column-name="issue_date"
                  :model-value="issueDate"
                  :modal-form="true"
                  :clearable="false"
                )
                FormLabel(v-else key="hide_issue_date" label-text="発行日" label-value="-")
              .uk-margin-medium-left
                FormLabel(label-text="文書番号" :label-value="paymentStatementsNoRange")
        .uk-margin-small-bottom
          span 【自治体情報】
          .uk-margin-medium-left
            FormLabel(label-text="自治体名" :label-value="paymentStatementSetting.cityName")
          .uk-grid(class="uk-child-width-1-1@m uk-child-width-1-2@l")
            div
              .uk-margin-medium-left
                FormLabel(label-text="電話番号" :label-value="paymentStatementSetting.cityTel")
                FormLabel(label-text="担当者名" :label-value="paymentStatementSetting.cityContactPerson")
            div
              .uk-margin-medium-left
                FormLabel(label-text="郵便番号" :label-value="paymentStatementSetting.cityZipCd")
                FormLabel(
                  label-text="所在地"
                  :label-value="`${paymentStatementSetting.cityAddress1} ${paymentStatementSetting.cityAddress2} ${paymentStatementSetting.cityAddress3}`"
                )
        .uk-margin-small-bottom
          .uk-margin-small-bottom
            span 【通知書内容】
          .uk-margin-medium-left
            TableTempPaymentSummaries

    // 読み込み中
    .uk-modal-body(v-else key="modal_reading")
      .juno-modal-title.uk-margin-medium-bottom
        span 支払通知書情報を取得中です。しばらくお待ちください。
      .uk-width-expand.uk-text-center(uk-spinner)

    // フッター（ボタン）
    .uk-modal-footer.juno-modal-footer.uk-flex.uk-flex-right.uk-margin-medium-bottom
      .uk-margin-small-right
        ButtonCloseModal(id-key="close_modal_issue_payment_statement" label-text="戻る")
      .uk-margin-small-right
        ButtonCreatePaymentStatement(:request-path="props.requestPath" :issue-date="issueDate" :disabled="disabled" @create="onCreate")
</template>
