<script setup lang="ts">
import mtdOnClick from "@/composables/common/panels/donations/edit/button_delete_inquiry/methods/mtdOnClick";

import { useStore, Store } from 'vuex';
import { key } from '@/vuex';
import type { State } from '@/vuex';
const store:Store<State> = useStore(key);

interface Props {
  requestPath:string,
  inquiryId:number,
}
const props = defineProps<Props>();

const { onClick } = mtdOnClick(store);
</script>

<template lang="pug">
button#button_delete_inquiry.uk-button.uk-button-default(
  type="button"
  @click="onClick(requestPath, inquiryId)"
) 削除
</template>
